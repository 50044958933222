import React from "react";

const searchTags = props => (
    <ul className="popup-search-tag-list">
        {props.searchHistory.map((item,index) => {
            return <li key={"history-"+index} className="item history"><span onClick={()=>props.click(item)} className="tag">{item}</span></li>
        })}
        {props.trends.map((trend,index) => {
            return <li key={"trend-"+index} className="item trend"><span onClick={()=>props.click(trend)} className="tag">{trend}</span></li>
        })}
    </ul>
)

export default searchTags;
