import gql from 'graphql-tag';

export const AUDIENCEPRESENTATIVE_QUERY = gql`
  query getSuperContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Id
      Url
      IxName
      Text
      Similars {
        Description
        ContentTags {
          Name
        }
        Files {
          FileName
          FileId
          Url
        }
      }
      FormPages {
        Id
        Title
        Order
        Questions {
          Id
          Title
          Type
          Choices {
            Point
            Value
          }
          Description
          ClassName
          ValidationClass
        }
      }
    }
  }
`;

export const AUDIENCEPRESENTATIVE_VARIABLES = {
  url: '/iletisim-formu',
};
