import React, { Component } from "react";

import SearchOverlay from "./partial/overlay";
import Input from "./partial/input";
import Tags from "./partial/tags";
import Result from "./partial/result";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

const trends = []

class Search extends Component {
    constructor(props) {
        super(props);

        this.timer = 0;
        this.validValue = '';
        this.state = {
            currentValue: '',
            isSearchStart: false,
            trends: [],
            searchHistory: [],
            mainData: [],
            otherData: null,
            mainError: false,
            otherError: false,
            keyUpValid: true,
        }
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ trends: trends })
            this.searchHistory();
        }, 1000)
    };

    onChangeInput = e => {
        // e.persist();
        this.setState({ currentValue: e.target.value.replace("  ", " "), keyUpValid: false }, () => {
            // clear timeout
            if (this.timer) clearTimeout(this.timer);
            // set timer
            this.timer = setTimeout(() => {
                this.valueControl();
                this.setState({ keyUpValid: true })
            }, 500);
        })

    };

    submitHandler = e => {
        e.preventDefault();
        if (this.state.keyUpValid) {
            this.valueControl();
        }
    };

    valueControl = () => {
        if (this.state.currentValue && this.state.currentValue.length >= 3) {
            if (this.state.currentValue === this.validValue) {
                return false;
            }
            this.setState({ isSearchStart: true, mainData: [], mainError: false });

            this.getData(this.props.config.SearchUrl + '?q=' + encodeURIComponent(this.state.currentValue) + '&skip=0&take=6')
                .then(data => {
                    if (!data) {
                        return Promise.reject(
                            new Error(`Yükleme hatası: veri okunamadı`)
                        );
                    }
                    if (data.List) {
                        this.setData(data.List);
                    } else {
                        // hatalı data formatı
                        this.setError()
                        return Promise.reject(
                            new Error(`Veri formatı hatalı`)
                        );
                    }
                })
                .catch(error => {
                    // data yok
                    this.setError()
                    console.log(`Error: ${error.message}`)
                });

        } else {
            this.validValue = null;
            this.setState({ isSearchStart: false, mainData: [], mainError: false });
        }
    };

    setData = (data) => {
        this.setState({ mainData: data })
        if (this.state.mainData && this.state.mainData.length && this.state.otherData && this.state.otherData.length) {
            this.validValue = this.state.currentValue;
            this.searchHistory();
        } else {
            this.validValue = null;
        }
    };

    setError = (itemError) => {
        this.validValue = null;
        this.setState({ mainError: true })
    };

    getData(url) {
        return fetch(url)
            .then(response => {
                if (response.ok) {
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(
                        new Error(`Yukleme hatası: ${response.status}`)
                    );
                }
            })
            .then(response => response.json())
            .catch(error => {
                console.log(`Error: ${error.message}`)
            });
    };

    tagsClickHandler = (tag) => {
        this.setState({ currentValue: tag }, () => {
            this.valueControl();
        })
    };

    searchHistory = () => {
        let searchHistoryArray = localStorage.getItem("searchHistoryItems") ? JSON.parse(localStorage.getItem("searchHistoryItems")) : [];
        let isTrend = false;
        //is validValue trend and is search history item trend?
        this.state.trends.forEach(trend => {
            if (this.validValue === trend) {
                isTrend = true;
            }
            if (searchHistoryArray.indexOf(trend) > -1) {
                searchHistoryArray = searchHistoryArray.filter(historyItem => historyItem && historyItem !== trend);
            }
        });
        // new history tag or change position
        if (!isTrend && this.validValue) {
            if (searchHistoryArray.indexOf(this.validValue) > -1) {
                searchHistoryArray = searchHistoryArray.filter(item => item && item !== this.validValue);
            } else {
                if (searchHistoryArray.length > 2) {
                    searchHistoryArray.pop();
                }
            }
            searchHistoryArray.unshift(this.validValue);
        }
        localStorage.setItem("searchHistoryItems", JSON.stringify(searchHistoryArray));
        this.setState({ searchHistory: searchHistoryArray })
    };

    componentDidUpdate(prevProps){
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.props.onClose()
            this.setState({currentValue: ''})
        }
    }

    render() {
        return (
            <div className={`popup-search visible ${this.state.isSearchStart ? "typing" : ""}`}>
                <SearchOverlay onClose={this.props.onClose} />
                <div className="popup-search-container">
                    <section className="popup-search-input-row">
                        <Input value={this.state.currentValue} onChange={this.onChangeInput} submit={this.submitHandler} />
                        <Tags click={this.tagsClickHandler} trends={this.state.trends} searchHistory={this.state.searchHistory} />
                    </section>
                    <section className="popup-search-result-row">
                        <Result data={this.state.mainData} hasError={this.state.mainError} onClick={this.props.onClose} />
                    </section>
                    {
                        this.state.mainData.length > 0 &&
                        <Link to={"/ara/" + this.state.currentValue} className="ui-button outline">TÜM SONUÇLAR</Link>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const search = withRouter(props => <Search {...props} />)
const SearchWithRouter = connect(mapStateToProps)(search);

export default SearchWithRouter;

