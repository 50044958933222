import gql from 'graphql-tag';

export const ABOUT_QUERY = gql`
  query getSuperContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Id
      Url
      IxName
      Title
      Text
    }
  }
`;

export const ABOUT_VARIABLES = {
  url: '/hakkimizda',
};
