import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchRadioValue } from '../../common/helper'
import { setPlayingState, setRadioData } from '../../redux/radio/action'
import { withRouter } from 'react-router-dom'
import RadyodPlayer from '../player/RadyodPlayer'

const logo = require('../../img/radyodlogo.png?v=2');

class IFrameSmall extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            active: false,
            playing: false,
        }
    }

    componentDidMount() {
        fetchRadioValue(this.props.config.LiveRadioInfoUrl, (radioData) => setRadioData(radioData))
        setInterval(() => {
            fetchRadioValue(this.props.config.LiveRadioInfoUrl, (radioData) => setRadioData(radioData))
        }, 15000)
    }

    onClickPlay = (playing) => {
        if (playing)
            setPlayingState({
                page: 'layout',
                item: this.props.config.StreamUrl,
                index: 0,
            })
        this.setState({ playing })
    }

    render() {
        const { radioData } = this.props
        return (
            <div className='iframe-small-container'>
                <img className='iframe-logo' alt='radyod' src={logo}></img>
                <div className='iframe-program-title'>{radioData.nextProgramName}</div>
                <div className='iframe-song-name'>{radioData.songName}</div>
                {/* <div className="iframe-button-ellipse"> <div className="iframe-player-button"></div></div> */}{' '}
                <div className='iframe-radyodPlayer'>
                    <div id='radyodPlayer'>
                        <div className='iframe-button-ellipse'></div>
                    </div>
                    <RadyodPlayer
                        onClickPlay={() => this.onClickPlay(true)}
                        onPause={() => this.setState({ playing: false })}
                        onEnded={() => this.setState({ playing: false })}
                        playing={this.props.playingState.page === 'layout' && this.state.playing}
                        url={this.props.config.StreamUrl}
                        onlyButton={true}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const iframeSmall = withRouter(props => <IFrameSmall {...props} />)
export default connect(mapStateToProps)(iframeSmall)
