import React, { Component } from 'react';

class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="notfound-container">
        <span>404</span>
        <span>SAYFA BULUNAMADI</span>
        <a className="ui-button outline" href="/">ANA SAYFAYA DÖN<i className="fa fa-chevron-right"></i></a>
    </div>;
  }
}

export default Page404;
