import React, { Component } from 'react';
import SocialShare from './../../components/share/socialshare';
import { Query } from 'react-apollo';
import HtmlParse from 'html-react-parser';

import { NEWS_DETAIL_QUERY } from '../../query/newsdetail';
import { NEWSGALLERY_QUERY } from '../../query/newsgallery';
import Loading from '../../components/loading/loading';
import { MonthNameLocalizeToTR } from '../../common/helper';
import NewsCard from '../../components/card/newscard';
import SwiperLoading from '../../common/swiperloading';
import CustomSwiper from '../../common/customswiper';
import { SeeAllCard } from '../../components/card';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

class NewsDetail extends Component {
  constructor(props){
    super(props)
    this.state = {
      sharePopupVisible: false
    }
    this.galleryContent = null;
    this.photoGalleryItems = document.getElementsByClassName('photo-gallery-item') || [];
    window.addEventListener('scroll', this.handleScroll);
  }

    pushGalleryToBdmp = (item, pageType, event = 'gallery', index = 0) => {
        if (pageType === '/foto-galeriler/') {
            this.galleryContent = item;
            window.bdmp('gallery', {
                'canonicalUrl': window.location.origin + item.Url,
                'quantity': item.Files.length
            });
            if (event === 'galleryView') {
                window.bdmp('galleryView', {
                    'canonicalUrl': window.location.origin + item.Url,
                    'index': index + 1
                });
                console.log({
                    'canonicalUrl': window.location.origin + item.Url,
                    'index': index + 1
                });
            }
        }
    }

    findClosest = (numbers, target) => {
        let closest = numbers[0];
        let closestDiff = Math.abs(target - closest);
        for (let i = 1; i < numbers.length; i++) {
            let current = numbers[i];
            let currentDiff = Math.abs(target - current);
            if (currentDiff < closestDiff) {
                closest = current;
                closestDiff = currentDiff;
            }
        }
        return closest;
    }

    handleScroll = (event) => {
        if (this.galleryContent && window.scrollY % 50 == 0) {
            let arr = new Array;
            Array.from(this.photoGalleryItems).forEach(item => {
                arr.push(item.offsetTop)
            })            
            let closest = this.findClosest(arr, window.scrollY);
            this.pushGalleryToBdmp(this.galleryContent, '/foto-galeriler/', 'galleryView', arr && arr.indexOf(closest))
        }
    }

  render() {
    return (
      <div className="list-detail">
        <Query variables={{ url: this.props.match.url }} query={NEWS_DETAIL_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />;
            } else if (error) console.error(error);
            else {
              var item = data.SuperContent;
              var date = new Date(item.ModifiedDate);
              var pageType = data.SuperContent.Ancestors[0].SelfPath;
              this.pushGalleryToBdmp(item, pageType);
              return (
                <div className="row">
                        <Helmet>
                          <title>{item.Title} - Radyo D</title>
                          <meta name='description' content={item.Description}></meta>
                          <meta property="creators" content={data.SuperContent.CreatedBy} />
                        </Helmet>
                  <div className="col-12 col-lg-12">
                    <div className="list-detail-header">
                      <h1>{item.Title}</h1>
                      <span className="date">
                        {date.getDate() + ' ' + MonthNameLocalizeToTR(date.getMonth() + 1) + ' ' + date.getFullYear()} - {date.getHours()}:
                        {date.getMinutes()}
                      </span>
                      <p>{item.Description}</p>
                      <SocialShare 
                        title={item.Title} 
                        url={this.props.config.ProductionUrl + this.props.match.url}
                        visible={this.state.sharePopupVisible}
                        visibleEvent={visible => this.setState({ sharePopupVisible: visible })}
                      >
                        <div className="social-links">
                          <button onClick={() => this.setState({sharePopupVisible: true})} className="btn-share">
                            <i className="far fa-share-square"></i> Paylaş
                          </button>
                        </div>
                      </SocialShare>
                    </div>
                    <div className="list-detail-container">
                      {pageType === '/foto-galeriler/' ? (
                        <div className="photo-gallery-container">
                          {data.SuperContent.Files.map((item, index) => {
                            return (
                              <div className="photo-gallery-item" key={index}>
                                <img src={item.Url} alt={item.Metadata.Description}></img>
                                <p>{item.Metadata.Description}</p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="list-detail-item">
                          {HtmlParse(item.Text)}
                          {item.Files.map((file, index) => {
                            return (
                                <div key={index}>
                                    <img className="list-detail-item-image" src={file.Url} alt={item.Title}></img>
                                    <p>{file.Metadata.Description}</p>
                                </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          }}
        </Query>

        <Query
          variables={{
            top: 8,
            skip: 0,
            filter: "Ancestors/any(a: a/Id eq '57fcb9d5f5ac764c604f1edb' or a/Id eq '59bbb8e1ac1fe32fe46a81ad') and Url ne '"+ this.props.match.url + "'",
            q: '',
            orderBy: 'StartDate desc',
          }}
          query={NEWSGALLERY_QUERY}
        >
          {({ data, loading, error }) => {
            if (loading || !data) return <SwiperLoading />;
            if (error) console.error(error);
            else {
              return (
                <section className="section fluid news-section">
                  <h2 className="section-title">{data.Filter[0].Ancestors[0].Title}</h2>
                  <CustomSwiper>
                    {data.Filter.map((item, index) => {
                      return (
                        <NewsCard
                          key={index}
                          index={index}
                          url={item.Url}
                          title={item.Title}
                          imagePath={item.Files[0].Url}
                          date={item.StartDate}
                        />
                      );
                    })}
                    <SeeAllCard url={'/haber-galeri'} />
                  </CustomSwiper>
                </section>
              );
            }
          }}
        </Query>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({...state.radioReducer})
const newsDetail = withRouter(props => <NewsDetail {...props} />)
const NewsDetailWithRouter = connect(mapStateToProps)(newsDetail);

export default NewsDetailWithRouter;
