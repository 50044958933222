import React, { Component } from 'react';

const calculate = (current, itemCount, pageSize) => {
  var list = [];
  var total = Math.ceil(itemCount / pageSize);
  var i = 0; //for loops
  if (total <= 7) {
    for (i = 1; i <= total; i++) {
      list.push(i);
    }
  } else {
    if (current <= 4) {
      for (i = 1; i <= 5; i++) {
        list.push(i);
      }
      list.push(null, total);
    } else if (total - current <= 3) {
      for (i = total; i > total - 5; i--) {
        list.unshift(i);
      }
      list.unshift(1, null);
    } else {
      list.push(current);
      list.unshift(current - 1);
      list.unshift(null);
      list.unshift(1);
      list.push(current + 1);
      list.push(null);
      list.push(total);
    }
  }
  return list;
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      itemCount: 0,
      pageSize: 0,
    };
  }

  isValidate = (current, itemCount, pageSize) => {
    if (current == null || itemCount == null || pageSize == null) return false;
    if (Math.ceil(itemCount / pageSize) < current) return false;
    return true;
  };

  updateParams = (current, itemCount, pageSize) => {
    if (this.state.current !== current || this.state.itemCount !== itemCount || this.state.pageSize !== pageSize)
      this.setState({ current, itemCount, pageSize });
  };

  componentDidMount() {
    this.updateParams(this.props.current, this.props.itemCount, this.props.pageSize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.current !== this.props.current ||
        prevProps.itemCount !== this.props.itemCount ||
        prevProps.pageSize !== this.props.pageSize
      )
      this.updateParams(this.props.current, this.props.itemCount, this.props.pageSize);
  }

  render() {
    return this.state.itemCount / this.state.pageSize <= 1 || this.state.current === 0 ? null : !this.isValidate(
        this.state.current,
        this.state.itemCount,
        this.state.pageSize,
      ) ? (
      <div className="pagination-container">'Not valid params for pagination'</div>
    ) : (
      <div className="pagination-container">
        {calculate(this.state.current, this.state.itemCount, this.state.pageSize).map((item, index) => {
          return (
            <div
              key={index}
              className={
                item === this.state.current
                  ? 'pagination-box active'
                  : item == null
                  ? 'pagination-box not-number'
                  : 'pagination-box'
              }
              onClick={item == null ? e => {} : e => this.props.onchangePage(item)}
            >
              <span>{item === null ? '...' : item}</span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Pagination;
