import React from 'react';
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';

import { GET_PODCASTLIST_QUERY, GET_PODCASTINFO_QUERY } from '../../query/podcastlist';
import RadyodPlayer from '../../components/player/RadyodPlayer';
import PodcastAnimation from './podcastAnimation';
import { calculateDurationDisplay } from '../../common/helper';
import { setPlayingState } from '../../redux/radio/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class PodcastDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panel: null,
      isPlaying: false,
      showVideo: false,
      pathName: '',
      podcasts: null,
    }

    var urlParams = this.props.history.location.pathname.split('/');
    if (urlParams.length > 3) {
      var safeUrl = urlParams[0] + '/' + urlParams[1] + '/' + urlParams[2];
      this.props.history.push(safeUrl);
    }
  }

  componentDidMount() {
    this.setState({
      pathName: this.props.history.location.pathname,
    });
  }

  getText = person => {
    return `${person} Podcast sayfasında ${person} Podcast son bölümünü ve geçmişten bugün yayınlanan tüm Podcast yayınlarını dinleyebilirsiniz.`
  }

  render() {
    const GET_PODCASTLIST_VARIABLES = {
      top: '1000',
      skip: '0',
      filter: "Ancestors/any(a: a/SelfPath eq '/podcastd/" + this.props.match.params.id + "/') and ContentType eq 'Clip'",
      q: '[]',
      orderBy: 'StartDate desc',
    };
    return (
      <>
        <Query variables={{ url: '/podcastd/' + this.props.match.params.id }} query={GET_PODCASTINFO_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />;
            } else if (error) console.error(error);
            else {
              return (
                <div className="podcast-header">
                  <Helmet>
                    <title>{data.SuperContent.Title} - Radyo D</title>
                    <meta name='description' content={this.getText(data.SuperContent.Title)}></meta>
                  </Helmet>
                  <div className="artist-img">
                    <img src={data.SuperContent.Files[1].Url} alt={data.SuperContent.Title} />
                  </div>
                  <div className="header-description">
                    <h2>{data.SuperContent.Title}</h2>
                    <h3>
                      {data.SuperContent.Properties.map((item, index) => {
                        return <span key={index}>{item.Value} </span>;
                      })}
                    </h3>
                    <p>{data.SuperContent.Description}</p>
                    {
                      this.state.podcasts !== null && this.state.podcasts.length !== 0
                      && <button onClick={() => document.querySelectorAll('.podcast-item')[0].click()} className="ui-button primary">
                        <i className="fa fa-play" /> SON PODCASTİ DİNLE!
                          </button>
                    }
                  </div>
                </div>
              );
            }
          }}
        </Query>
        <Query variables={GET_PODCASTLIST_VARIABLES} query={GET_PODCASTLIST_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />;
            } else if (error) console.error(error);
            else {
              this.state.podcasts === null && this.setState({ podcasts: data.Filter })
              return (
                <div className="collapse">
                  {data.Filter.map((item, index) => {
                    return (
                      <div key={index}>
                        <div key={index} className="collapse-item">
                          <div
                            className={this.state.panel === index ? 'podcast-item collapse-header active' : 'podcast-item collapse-header'}
                            data-content-id={item.Id}
                            data-media-url={item.MediaFiles[0].Url}
                            onClick={() => this.setState({ panel: this.state.panel == null ? index : this.state.panel === index ? null : index })}
                          >
                            <div className="info">
                              <h3>{item.Title}</h3>
                              <p>{item.Description}</p>
                            </div>
                            <span className="duration"> {calculateDurationDisplay(item.Duration)} </span>
                            <i className={this.state.panel === index ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
                          </div>
                          {
                            this.state.panel === index &&
                            <div className='collapse-panel show'>
                              <div className="player">
                                <PodcastAnimation active={this.state.isPlaying} />
                                <div onClick={() => this.props.history.push(this.state.pathName + '/' + item.IxName, { podcastPlay: true })} className="podcast-player-wrapper">
                                  <RadyodPlayer
                                    onClickPlay={() => setPlayingState({
                                      page: 'podcast',
                                      item: item.MediaFiles[0].Url,
                                      index: index
                                    })}
                                    onPlay={() => this.setState({ isPlaying: true })}
                                    onPause={() => this.setState({ isPlaying: false })}
                                    onEnded={() => this.setState({ isPlaying: false })}
                                    playing={this.props.playingState.page === 'podcast' && this.props.playingState.index === index}
                                    size={'md'}
                                    url={item.MediaFiles[0].Url}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                          </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          }}
        </Query>
      </>
    );
  }
}


const mapStateToProps = (state) => ({ ...state.radioReducer })
const podcastDetail = withRouter(props => <PodcastDetail {...props} />)
const PodcastDetailWithRouter = connect(mapStateToProps)(podcastDetail);

export default PodcastDetailWithRouter;
