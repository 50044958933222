import React from 'react'
import { useSelector } from "react-redux"
import TwitterIcon from '../icons/TwitterIcon'

export function SocialMediaAccounts() {
  const config = useSelector(x => x.radioReducer.config)
  return (
    <ul className="social-list">
      <li>
        <a
          href={config.FacebookUrl}
          rel="noopener noreferrer"
          target="_blank"
          className="social-link"
        >
          <i className="fab fa-facebook-square"></i>
        </a>
      </li>
      <li className='social-tw'>
          <a
            href={config.TwitterUrl}
            rel="noopener noreferrer"
            target="_blank"
            className="social-link"
          >
            <TwitterIcon/>
            
          </a>
      </li>
      <li>
          <a
            href={config.InstagramUrl}
            rel="noopener noreferrer"
            target="_blank"
            className="social-link"
          >
            <i className="fab fa-instagram"></i>
          </a>
      </li>
      <li>
        <a
          href={config.YoutubeUrl}
          rel="noopener noreferrer"
          target="_blank"
          className="social-link"
        >
          <i className="fab fa-youtube"></i>
        </a>
      </li>
    </ul>
  )
}