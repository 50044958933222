import gql from 'graphql-tag';

export const GET_PROGRAMLIST_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Id
      Url
      IxName
      Title
      Duration
      Description
      Text
      Files {
        Url
      }
      MediaFiles {
        Url
      }
      Relations
      Schedule
      Properties {
        IxName
        Value
        SelectValues(Status:true)
        {
          Selected
          IxName
        }
      }
    }
  }
`;

export const GET_PROGRAMLIST_VARIABLES = {
  top: '50',
  skip: '0',
  filter: "Ancestors/any(a: a/SelfPath eq '/program-podcast/') and ContentType eq 'TVShowContainer'",
  q: "['Clip']",
  orderBy: 'StartDate desc',
};

export const GET_PODCASTDLIST_VARIABLES = {
  top: '50',
  skip: '0',
  filter: "Ancestors/any(a: a/SelfPath eq '/podcastd/') and ContentType eq 'TVShowContainer'",
  q: "['Clip']",
  orderBy: 'StartDate desc',
};
