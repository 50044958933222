import gql from 'graphql-tag';

export const NEWSGALLERY_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Ancestors {
        Title
        SelfPath
      }
      Id
      Url
      IxName
      Title
      SelfPath
      Text
      Duration
      Description
      StartDate
      Files {
        Url
        Metadata {
          Description
        }
      }
    }
  }
`;

export const NEWSGALLERY_VARIABLES = {
  top: 8,
  skip: 0,
  filter: "Ancestors/any(a: a/Id eq '57fcb9d5f5ac764c604f1edb' or a/Id eq '59bbb8e1ac1fe32fe46a81ad')",
  q: '',
  orderBy: 'StartDate desc',
};
