/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react'
import { copyToClipboard } from '../../common/helper'
import { toastType, showToast, toastPosition } from '../../common/toast'
import { Helmet } from 'react-helmet';

const originUrl = window.location.origin
const iframeLarge =
    '<iframe width="300" height="251" src="' +
    originUrl +
    '/iframe-large" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
const iframeSmall =
    '<iframe width="345" height="65" src="' +
    originUrl +
    '/iframe-small" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'

class AddWebsitePage extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="static-page-container">
            <Helmet>
                <title>Sitene Ekle - Radyo D</title>
                <meta name='description' content="Sitenizde Radyo D çalması için bu sayfadan gerekli kodlara ulaşabilirsiniz."></meta>
            </Helmet>
                <div className="addwebsite-container">
                    <h2>Sitene Ekle</h2>
                    <h3>Radyo D Widget'ını Sitenize Ekleyin</h3>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="radyod-iframe">
                                <div className="iframe-container">
                                    <iframe
                                        width="300"
                                        height="251"
                                        src={originUrl + "/iframe-large"}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <h3>Büyük Görünüm</h3>
                                <div className="iframe-textbox">{iframeLarge}</div>
                                <button
                                    onClick={() => {
                                        copyToClipboard(iframeLarge)
                                        showToast('Kopyalandı!', 2000, toastType.success, toastPosition.bottomCenter)
                                    }}
                                    className="ui-button primary"
                                >
                                    <i className="fas fa-code" /> Kodu Kopyala
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="radyod-iframe">
                                <div className="iframe-container">
                                    <iframe
                                        width="345"
                                        height="65"
                                        src={originUrl + "/iframe-small"}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <h3>Küçük Görünüm</h3>
                                <div className="iframe-textbox">{iframeSmall}</div>
                                <button
                                    onClick={() => {
                                        copyToClipboard(iframeSmall)
                                        showToast('Kopyalandı!', 2000, toastType.success)
                                    }}
                                    className="ui-button primary"
                                >
                                    <i className="fas fa-code" /> Kodu Kopyala
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddWebsitePage
