import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PodcastArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        key={this.props.index}
        onClick={this.props.onChangeNews !== undefined ? () => this.props.onChangeNews(this.props.url) : undefined}
        className="swiper-slide"
      >
        <Link to={this.props.url} className={`news-card-v2 mobile-horizontal ${this.props.mobile ? "mobile" : ""}`} title={this.props.title}>
          <figure className="figure">
            <div className="img-wrap">
              <span className="bg-image" style={{ backgroundImage: "url('" + this.props.imagePath + "')" }}></span>
            </div>
          </figure>
          <div className='slide-button'>
            <span>
              PODCASTLER <i className='fa fa-chevron-right'></i>
            </span>
          </div>
        </Link>
      </div>
    );
  }
}

export default PodcastArea;
