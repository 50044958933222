import React, { Component } from 'react';
import ReactIdSwiper from 'react-id-swiper';
import autoSwiperParams from './autoSwiperParams';
import 'swiper/dist/css/swiper.min.css';

class SocialMediaSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextEl: null,
      prevEl: null,
      prevArrowVisible: false,
      nextArrowVisible: true,
    };
  }

  getSwiperRef = e => {
    if (e.pagination.bullets != null) {
      e.on('slideChange', () => {
        const maxSwipeValue = e.pagination.bullets.length - 1;
        if (e.snapIndex === 0) this.setState({ prevArrowVisible: false });
        else if (e.snapIndex === maxSwipeValue) this.setState({ nextArrowVisible: false });
        else this.setState({ prevArrowVisible: true, nextArrowVisible: true });
      });
    }
  };

  render() {
    return (
      <div className="section-swiper news-swiper">
        <ReactIdSwiper getSwiper={e => e != null && this.getSwiperRef(e)} {...autoSwiperParams}>
          {this.props.children}
        </ReactIdSwiper>
      </div>
    );
  }
}

export default SocialMediaSwiper;
