import gql from 'graphql-tag';

export const NEWS_DETAIL_QUERY = gql`
  query getContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Id
      Title
      StartDate
      Description
      Url
      IxName
      Text
      Duration
      ModifiedDate
      CreatedBy
      Ancestors {
        ContentType
        Title
        SelfPath
      }
      Files {
        Url
        Metadata {
          Description
        }
      }
      MediaFiles {
        Url
      }
    }
  }
`;
