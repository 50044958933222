import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SeeAllNewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="swiper-slide">
        <Link to={this.props.url}>
          <div className="see-all-card new">
            <span>
              <i className="fas fa-chevron-right"></i> Tümünü Gör
            </span>
          </div>
        </Link>
      </div>
    );
  }
}

export default SeeAllNewCard;
