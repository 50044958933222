import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Instagram extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = { ...this.props.data };
    return (
      /*<section className="section instagram-widget fluid dark">
        <div className="row">
          <div className="col-12 col-md-auto">
            <div className="widget-img-wrapper">
              <div className="widget-img" title={data.Description}>
                <span style={{ backgroundImage: "url('" + data.Files[0].Url + "')" }}></span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-auto tag">
            <h3 className="tag-title">{data.Title}</h3>
            <p className="tag-description">{data.Description}</p>
            <a href={this.props.config.InstagramUrl} className="ui-button outline" target="_blank" rel="noopener noreferrer">
              SOHBETE KATIL <i className="fa fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </section>*/
      <div></div>
    );
  }
}

const mapStateToProps = (state) => ({...state.radioReducer})
const instagram = withRouter(props => <Instagram {...props} />)
const InstagramWithRouter = connect(mapStateToProps)(instagram);

export default InstagramWithRouter;
