import { isWeb } from './helper';

let swiperParams = {
  slidesPerView: 'auto',
  spaceBetween: 20,
};

if (isWeb()) {
  swiperParams = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-navigation-next',
      prevEl: '.swiper-navigation-prev',
    },
  };
}

export default swiperParams;
