import React, { Component } from 'react';
import ReactIdSwiper from 'react-id-swiper';
import swiperParams from './swiperParams';
import 'swiper/dist/css/swiper.min.css';

class CustomSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextEl: null,
      prevEl: null,
      prevArrowVisible: false,
      nextArrowVisible: true,
    };
  }

  getSwiperRef = e => {
    if (e.pagination.bullets != null) {
      e.on('slideChange', () => {
        const maxSwipeValue = e.pagination.bullets.length - 1;
        if (e.snapIndex === 0) this.setState({ prevArrowVisible: false });
        else if (e.snapIndex === maxSwipeValue) this.setState({ nextArrowVisible: false });
        else this.setState({ prevArrowVisible: true, nextArrowVisible: true });
      });
    }

    this.setState({ nextEl: e.navigation.nextEl, prevEl: e.navigation.prevEl });
  };

  render() {
    return (
      <div className="section-swiper news-swiper">
        <ReactIdSwiper getSwiper={e => e != null && this.getSwiperRef(e)} {...swiperParams}>
          {this.props.children}
        </ReactIdSwiper>
        {this.state.prevArrowVisible && (
          <div onClick={() => this.state.prevEl.click()} className="swiper-navigation-prev">
            <i className="fas fa-chevron-left"></i>
          </div>
        )}
        {this.state.nextArrowVisible && (
          <div onClick={() => this.state.nextEl.click()} className="swiper-navigation-next">
            <i className="fas fa-chevron-right"></i>
          </div>
        )}
      </div>
    );
  }
}

export default CustomSwiper;
