import React, { Component } from 'react'
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';
import { Link } from 'react-router-dom';
import { VIDEOGALLERYCOUNT_QUERY, VIDEOGALLERYCOUNT_VARIABLES, VIDEOGALLERY_QUERY } from '../../query/videogallery';
import Pagination from '../../common/pagination';
import { calculateDurationDisplay } from '../../common/helper';
import { Helmet } from 'react-helmet';

const LoadingImage = require('../../img/loading-card-image.png?v=2');

export default class VideoGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            pageSize: 15,
            variables: {
                top: 15,
                skip: 0,
                filter: "Ancestors/any(a: a/Id eq '60112704ebc8302e3c457faf')",
                q: '',
                orderBy: 'StartDate desc',
            },
        };
    }

    onchangePage = selectedPage => {
        if (this.state.currentPage !== selectedPage) {
            this.setState({
                currentPage: selectedPage,
                variables: {
                    top: this.state.pageSize,
                    skip: (selectedPage - 1) * this.state.pageSize,
                    filter: "Ancestors/any(a: a/Id eq '60112704ebc8302e3c457faf')",
                    q: '',
                    orderBy: 'StartDate desc',
                },
            });
        }
    };

    render() {
        return (
            <div className="listing-holder">
                <Helmet>
                    <title>Video Klipler ve En Çok İzlenenler - Radyo D</title>
                    <meta name='description' content="Video Klipler sayfasında Radyo D'de en çok sevilen müzik ve şarkıların kliplerini izleyebilirsiniz."></meta>
                </Helmet>
                <Query variables={this.state.variables} query={VIDEOGALLERY_QUERY}>
                    {({ data, loading, error }) => {
                        if (loading || !data) {
                            return <Loading />;
                        } else if (error) console.error(error);
                        else {
                            const title = data.Filter[0].Ancestors[0].Title;
                            return (
                                <>
                                    <h1 className="cat-title">{title}</h1>
                                    <div className="row">
                                        {data.Filter.map((item, index) => {
                                            var detailPath = 'video' + item.Url;
                                            return (
                                                <div key={index} className="item col-12 col-md-4 col-lg-4">
                                                    <Link to={detailPath} className="news-card-v2 mobile-horizontal" title={item.Title}>
                                                        <figure className="figure">
                                                            <div className="img-wrap">
                                                                <span className="bg-image" style={{ backgroundImage: item.Files.length < 1 ? "url('" + LoadingImage + "')" : "url('" + item.Files[0].Url + "')" }}></span>
                                                                <i className="ico video"></i>
                                                                <span className="flag-dark">
                                                                    {calculateDurationDisplay(item.Duration)}
                                                                </span>
                                                            </div>
                                                            <figcaption className="caption">
                                                                <p className="spot">{item.Title}</p>
                                                            </figcaption>
                                                        </figure>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Query variables={VIDEOGALLERYCOUNT_VARIABLES} query={VIDEOGALLERYCOUNT_QUERY}>
                                        {({ data, loading, error }) => {
                                            if (loading || !data) {
                                                return <Loading />;
                                            } else if (error) console.error(error);
                                            else {
                                                const count = data.FilterCount.Count;
                                                return (
                                                    <Pagination
                                                        onchangePage={this.onchangePage}
                                                        current={this.state.currentPage}
                                                        itemCount={count}
                                                        pageSize={this.state.pageSize}
                                                    />
                                                );
                                            }
                                        }}
                                    </Query>
                                </>
                            );
                        }
                    }}
                </Query>
            </div>
        );
    }
}
