import React, { Component } from 'react';

import ResponsiveBanner from "../components/responsive-banner/index";
import Footer  from "../components/footer/footer";
import Header from "../components/header/header";
import Radio from "../components/radio/radio";
import Lightbox from '../components/lightbox';

class DefaultLayout extends Component {
  render() {
    return (
      <>      
      <Header />
        <main>
          <div className="container-fluid">
            <div className="row">
              <Radio />
              <div className="col-12 left-content">
                <div className="main-wrapper">
                  <ResponsiveBanner />
                  <Lightbox />
                  {this.props.children}
                  </div>
                <Footer />
              </div>
            </div>
          </div>
        </main>
        </>
    )
  }
}

export default DefaultLayout;
