import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';

import { GET_PODCASTDLIST_VARIABLES, GET_PROGRAMLIST_QUERY } from '../../query/programlist';
import { Helmet } from "react-helmet";

class Podcast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonStatus: null,
        };
    }

    programList() {
        return (
            <Query variables={GET_PODCASTDLIST_VARIABLES} query={GET_PROGRAMLIST_QUERY}>
                {({data, loading, error}) => {
                    if (loading || !data) {
                        return <Loading/>;
                    } else if (error) console.error(error);
                    else
                        return (
                            <>
                                <div className="podcast-container">
                                    <div className="row">
                                        {data.Filter.map((item, index) => this.podcastItem(item, index, false))}
                                    </div>
                                </div>
                                {data.Filter.find(x => x.Properties.find(x => x.IxName === 'ShowInOther')?.SelectValues?.find(x => x.Selected)?.IxName === 'true') ? (
                                    <div className="podcast-other-container">
                                        <h2 className="podcast-other-title">DAHA FAZLA PODCAST</h2>
                                        <div className="row">
                                            {data.Filter.map((item, index) => this.podcastItem(item, index, true))}
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        )
                }}
            </Query>
        );
    }

    podcastItem(item, index, showInOther) {
        let showInOtherProp = item.Properties.find(x => x.IxName === 'ShowInOther')?.SelectValues?.find(x => x.Selected)?.IxName === 'true';

        if (showInOtherProp !== showInOther) {
            return null;
        }

        return (
            <div key={index} className="col-12 col-md-4 podcast-element">
                <div className="default-card">
                    <figure className="figure">
                        <Link to={'/podcast/' + item.IxName}>
                            <div onClick={() => this.props.history.push('/podcast/' + item.IxName)} className="img-wrap">
                                <img src={item.Files[0].Url} alt="Lorem"/>
                                {item.Relations.length > 0 ? (
                                    <span className="hover-text">
                                        <div to={'/podcast/' + item.IxName} className="ui-button outline">
                                            {' '}
                                            PODCASTLER <i className="fa fa-chevron-right"/>
                                        </div>
                                    </span>
                                ) : null}
                            </div>
                        </Link>
                        <figcaption className="caption">
                            <h3 className="title">{item.Title}</h3>
                            <p className="spot">
                                {item.Schedule}
                                <br></br>
                                {item.Relations.length > 0 ? (
                                    <Link to={'/podcast/' + item.IxName}>
                                        {' '}
                                        PODCASTLER <i className="fa fa-chevron-right"/>
                                    </Link>
                                ) : null}
                            </p>
                        </figcaption>
                    </figure>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Podcast - Radyo D</title>
                    <meta name='description' content="Podcast sayfasında Radyo D'de yayınlanan tüm Podcast yayınlarını dinleyebilirsiniz."></meta>
                </Helmet>
                {this.programList()}
            </>
        );
    }
}

export default Podcast;
