import React from "react";
import Loading from '../../loading/loading'
import NoResult from './noResult';
import NewsCard from '../../card/newscard'
import VideoCard from '../../card/videocard'
import CustomSwiper from '../../../common/customswiper'
import { isWeb } from "../../../common/helper";

const searchResult = props => {
    let result = <Loading />;
    if (props.hasError) {
        result = <NoResult hasError={props.hasError} />
    }
    if (props.data) {
        if (props.data.length) {
            if (isWeb()) {
                result = <div className="search-result row">
                    {props.data.map((item, index) => {
                        if (item.ContentType !== "Clip") {
                            return (
                                <div className="col-md-4">
                                    <NewsCard
                                        key={index}
                                        index={index}
                                        url={item.Url}
                                        title={item.Title}
                                        imagePath={item.Images[0]}
                                        date={item.StartDate}
                                    />
                                </div>
                                
                            );
                        } else {
                            return (
                                <div className="col-md-4">
                                    <VideoCard
                                        key={index}
                                        index={index}
                                        url={item.Url}
                                        title={item.Title}
                                        imagePath={item.Images[0]}
                                        date={item.StartDate}
                                        duration={item.Duration}
                                    />
                                </div>
                                
                            );
                        }
                    })}
                </div>
            }
            else {
                result = (
                    <CustomSwiper>
                        {props.data.map((item, index) => {
                            if (item.ContentType !== "Clip") {
                                return (
                                    <NewsCard
                                        key={index}
                                        index={index}
                                        url={item.Url}
                                        title={item.Title}
                                        imagePath={item.Images[0]}
                                        date={item.StartDate}
                                    />
                                );
                            } else {
                                return (
                                    <VideoCard
                                        key={index}
                                        index={index}
                                        url={item.Url}
                                        title={item.Title}
                                        imagePath={item.Images[0]}
                                        date={item.StartDate}
                                        duration={item.Duration}
                                    />
                                );
                            }
                        })}
                    </CustomSwiper>
                )
            }

        } else {
            result = <NoResult hasError={props.hasError} />
        }
    }
    return (
        <section className="section fluid main-results">
            <div className="container">
                <h2 className="section-title">SONUÇLAR</h2>
                <div className={`ps-result-inner ${props.data || props.hasError ? "loaded" : ""}`}>
                    {result}
                </div>
            </div>
        </section>
    )
}

export default searchResult;
