import React from "react";

const searchOverlay = props => (
    <>
        <span onClick={props.onClose} className="popup-search-overlay"></span>
        <span onClick={props.onClose} className="popup-search-close"></span>
    </>
);

export default searchOverlay;
