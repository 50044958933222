import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { calculateDurationDisplay } from '../../common/helper'
import './RadyodPlayer.scss'

export default class RadyodPlayer extends Component {
    constructor(props) {
        super(props)
        this.playerRef = React.createRef();

        this.state = {
            isLive: null,
            playing: this.props.playing != null ? this.props.playing : false,
            muted: false,
            volume: 0.5,
            visibleVerticalVolume: false,
            duration: 0,
            progress: {
                loaded: 0,
                loadedSeconds: 0,
                played: 0,
                playedSeconds: 0
            }
        }
    }

    getVolumeIcon = () => {
        if (this.state.muted)
            return 'fa-volume-mute'
        else if (this.state.volume < 0.1)
            return 'fa-volume-off'
        else if (this.state.volume < 0.7)
            return 'fa-volume-down'
        else if (this.state.volume <= 1)
            return 'fa-volume-up'

        return ''
    }

    setSeekSecond = (second) => {
        this.setState({ progress: { ...this.state.progress, playedSeconds: second } })
        this.playerRef.current.seekTo(second, 'seconds')
    }

    getLiveDom = () => {
        return (
            <>
                <div className="mute-button"
                    onClick={() => !this.setState({ muted: !this.state.muted })}
                >
                    <i className={"fa " + this.getVolumeIcon()}></i>
                </div>
                <input
                    className="volume-bar"
                    onChange={(e) => this.setState({ muted: false, volume: e.target.value / 100 })}
                    type="range"
                    min={0}
                    max={100}
                    value={this.state.muted ? 0 : this.state.volume * 100}
                />
            </>
        )
    }

    getPlayerDom = () => {
        return (
            <>
                <div
                    className={this.state.visibleVerticalVolume ? "volume-element active" : "volume-element"}
                    onMouseEnter={() => this.setState({ visibleVerticalVolume: true })}
                    onMouseLeave={() => this.setState({ visibleVerticalVolume: false })}
                >
                    {
                        this.state.visibleVerticalVolume &&
                        <input
                            className="volume-bar vertical"
                            onChange={(e) => this.setState({ muted: false, volume: e.target.value / 100 })}
                            type="range"
                            min={0}
                            max={100}
                            value={this.state.muted ? 0 : this.state.volume * 100}
                        />
                    }
                    <div className="mute-button"
                        onClick={() => !this.setState({ muted: !this.state.muted })}
                    >
                        <i className={"fa " + this.getVolumeIcon()}></i>
                    </div>
                </div>
                <div className="progress-bar-wrapper">
                    <div className="current-with-duration">
                        {calculateDurationDisplay(this.state.progress.playedSeconds)} / {calculateDurationDisplay(this.state.duration)}
                    </div>
                    <input
                        className="progress-bar"
                        onChange={(e) => this.setSeekSecond(e.target.value)}
                        type="range"
                        min={0}
                        max={this.state.duration}
                        value={this.state.progress.playedSeconds}
                    />
                </div>
            </>
        )
    }

    onClickPlayButton = () => {
        this.setState({ playing: !this.state.playing })
        if (typeof this.props.onClickPlay == 'function')
            this.props.onClickPlay(this.state)
    }

    onProgress = () => {        
        if (typeof this.props.onProgress == 'function')
            this.props.onProgress(this.state)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.playing !== this.props.playing)
            this.setState({ playing: this.props.playing })
    }

    render() {
        return (
            <div className={this.props.size == null ? 'radyod-player-wrapper' : 'radyod-player-wrapper ' + this.props.size}>
                {
                    this.state.isLive != null &&
                    <div className="player-element-wrapper">
                        <div className="play-button" onClick={this.onClickPlayButton}>
                            <i className={this.state.playing ? 'fa fa-pause' : 'fa fa-play'}></i>
                        </div>
                        {
                            this.props.onlyButton !== true
                                ? this.state.isLive
                                    ? this.getLiveDom()
                                    : this.getPlayerDom()
                                : null
                        }
                    </div>
                }

                <ReactPlayer
                    playsinline
                    className="radyod-player"
                    ref={this.playerRef}
                    url={this.props.url}
                    playing={this.state.playing}
                    muted={this.state.muted}
                    volume={this.state.volume}
                    onProgress={progress => { this.setState({ progress }); this.onProgress(); }}
                    onDuration={duration => this.setState({ isLive: duration === Infinity, duration: duration })}
                    onEnded={() => { this.setState({ playing: false }); this.props.onEnded() }}
                    onPause={this.props.onPause}
                    onPlay={this.props.onPlay}
                    onStart={this.props.onStart}
                    onError={this.props.onError}
                    //config={{ file: { forceHLS: true } }}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />
            </div>
        )
    }
}
