import gql from 'graphql-tag';

export const GET_PODCASTLIST_QUERY = gql`
    query filter($filter: String!,$q: String!, $skip:Int!, $top:Int!, $orderBy:String!) {
        Filter(Filter:$filter, Q:$q,Skip:$skip, Top:$top, OrderBy:$orderBy)
        {
            Id
            Url
            IxName
            Title
            Duration
            Description
            SelfPath
            ContentType
            Files
            {
                Url
            }
            MediaFiles{
                Url
            }
            Relations
        }
    }
`;

export const GET_PODCASTINFO_QUERY = gql`
    query getSuperContentByUrl($url: String!) {
        SuperContent(Url:$url)
        {
            Id
            Title
            Description
            IxName
            Url 
            IxName 
            Description 
            ContentType
            Properties{ IxName Value } 
            Files{ 
                Id 
                FileName 
                Url(Q:75,W:0,H:0) 
                Application 
            } 
        }
    }
`;

  