import React, { Component } from 'react';
import { ValidatorForm } from 'react-form-validator-core';
import InputValidator from '../../forms/validateinput';
import SelectValidator from '../../forms/validateselect';
import TextareaValidator from '../../forms/validatetextarea';
import { Query } from 'react-apollo';
// import HtmlParse from 'html-react-parser';
import { AUDIENCEPRESENTATIVE_VARIABLES, AUDIENCEPRESENTATIVE_QUERY } from '../../query/audiencerepresentative';
import Loading from '../../components/loading/loading';
import { toastType, showToast } from '../../common/toast';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

class AudienceRepresentative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApproval: true,
      variables: {}
    };
  }

  mutationQuery = gql`
    mutation mryhod($ViewerCommunicationForm: ViewerCommunicationFormInput!) {
      ViewerCommunicationForm(ViewerCommunicationForm: $ViewerCommunicationForm) {
        FormId
      }
    }
  `;

  initializeData = (e, currentModel) => {
    var elements = e.target.elements;
    var values = [];

    for (var i = 0; i < elements.length; i++) {
      if (!(elements[i].name === '' || elements[i].value === '')) {
        var value = { QuestionId: elements[i].name, Answer: elements[i].value };
        value.QuestionId !== 'isApproval' && values.push(value);
      }
    }
    this.setState({variables: {
      ViewerCommunicationForm: {
        FormId: currentModel.Id,
        Answers: values,
      },
    }})    
    return this.state.variables;
  };

  handleChange = (inputName, value) => {
    // eslint-disable-next-line no-eval
    this.setState({ [eval('inputName')]: value });
  };

  render() {
    return (
      <div className="audience-representative-container">
        <Query variables={AUDIENCEPRESENTATIVE_VARIABLES} query={AUDIENCEPRESENTATIVE_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading></Loading>;
            } else if (error) {
              return console.error(error);
            }
            var value = data.SuperContent;
            return (
              <Mutation mutation={this.mutationQuery} variables={this.state.variables}>
                {(q, { data }) => (
                  <>
                    <h2>İzleyici Temsilcisi Formu</h2>
                    <ValidatorForm
                      ref="form"
                      onSubmit={e => {
                        q(this.initializeData(e, value));
                        showToast('Başarıyla Gönderildi!', 4000, toastType.success);
                        this.setState({ isApproval: false });
                      }}
                    >
                      {value.FormPages.map((formPage, index) => {
                        return (
                          <React.Fragment key={index}>
                            <h3 key={'formTitle' + index}>{formPage.Title}</h3>
                            <div key={'form' + index} className="row">
                              {formPage.Questions.map((question, index) => {
                                return (
                                  <div key={index} className={question.ClassName}>
                                    {(question.Type === 'Text' || question.Type === 'Date' || question.Type === 'Time') && (
                                      <InputValidator
                                        key={'input' + index}
                                        type={question.Type}
                                        onChange={e => this.handleChange(question.Id, e.target.value)}
                                        name={question.Id}
                                        value={this.state[question.Id]}
                                        validators={question.ValidationClass === '' ? null : question.ValidationClass.split(' ')}
                                        errorMessages={question.Description != null && question.Description.split(',')}
                                        placeholder={question.Title}
                                      />
                                    )}
                                    {question.Type === 'Select' && (
                                      <SelectValidator
                                        key={'select' + index}
                                        onChange={e => {
                                          this.handleChange(question.Id, e.target.value);
                                          question.Title === 'İl' &&
                                            this.setState({
                                              cityCode: formPage.Questions.filter(p => p.Title === 'İl')[0].Choices.filter(
                                                p => p.Value === e.target.value,
                                              )[0].Point,
                                            });
                                        }}
                                        name={question.Id}
                                        value={this.state[question.Id]}
                                        validators={question.ValidationClass === '' ? null : question.ValidationClass.split(' ')}
                                        errorMessages={question.Description != null && question.Description.split(',')}
                                        placeholder={question.Title}
                                        disabled={question.Title === 'İlçe' && this.state.cityCode == null}
                                      >
                                        <option value="" hidden>
                                          {question.Title}
                                        </option>
                                        {question.Title === 'İlçe'
                                          ? question.Choices.filter(p => p.Point === this.state.cityCode).map((choice, index) => {
                                              return (
                                                <option key={index} value={choice.Value}>
                                                  {choice.Value}
                                                </option>
                                              );
                                            })
                                          : question.Choices.map((choice, index) => {
                                              return (
                                                <option key={index} value={choice.Value}>
                                                  {choice.Value}
                                                </option>
                                              );
                                            })}
                                      </SelectValidator>
                                    )}
                                    {question.Type === 'Textarea' && (
                                      <TextareaValidator
                                        key={'textarea' + index}
                                        onChange={e => this.handleChange(question.Id, e.target.value)}
                                        name={question.Id}
                                        value={this.state[question.Id]}
                                        validators={question.ValidationClass === '' ? null : question.ValidationClass.split(' ')}
                                        errorMessages={question.Description != null && question.Description.split(',')}
                                        placeholder={question.Title}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        );
                      })}
                      {/* <div className="col-12">
                        <div className="contract">{HtmlParse(value.Text)}</div>
                      </div> */}
                      {/* <div className="col-12 approval-container">
                        <input
                          checked={this.state.isApproval ? 'checked ' : ''}
                          onChange={() => this.setState({ isApproval: !this.state.isApproval })}
                          name="isApproval"
                          type="checkbox"
                        />{' '}
                        <label onClick={() => this.setState({ isApproval: !this.state.isApproval })} htmlFor="isApproval">
                          Yarışma Başvuru Taahhütnamesini okudum ve kabul ediyorum.
                        </label>
                        <br />
                        {!this.state.isApproval && <span className="input-valid-message">Lütfen onaylayınız!</span>}
                      </div> */}

                      <div className="col-12 send-button">
                        <button disabled={!this.state.isApproval} type="submit" className="ui-button primary">
                          <i className="fas fa-paper-plane" /> GÖNDER
                        </button>
                      </div>
                    </ValidatorForm>
                  </>
                )}
              </Mutation>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default AudienceRepresentative;
