import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import trLocale from 'moment/locale/tr';
moment.updateLocale('tr', trLocale);

class NewsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        key={this.props.index}
        onClick={this.props.onChangeNews !== undefined ? () => this.props.onChangeNews(this.props.url) : undefined}
        className="swiper-slide"
      >
        <Link to={this.props.url} className={`news-card-v2 mobile-horizontal ${this.props.mobile ? "mobile" : ""}`} title={this.props.title}>
          <figure className="figure">
            <div className="img-wrap">
              <span className="bg-image" style={{ backgroundImage: "url('" + this.props.imagePath + "')" }}></span>
              <i className="ico"></i>
              <span className="flag-dark">
                <i className="fa fa-calendar-alt"></i>
                {moment(this.props.date)
                  .locale('tr')
                  .format('DD MMMM YYYY')}
              </span>
            </div>
          </figure>
          <p className="spot">{this.props.title}</p>
        </Link>
      </div>
    );
  }
}

export default NewsCard;
