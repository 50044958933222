
import { store } from '../..';
import { reduxConstants } from '../constants';

export function setRadioData(data){
    return store.dispatch({
        type: reduxConstants.SET_DATA,
        radioData: data
    })
}

export function setPlayingState(data) {
    return store.dispatch({
        type: reduxConstants.SET_DATA,
        playingState: data
    })
}

export function setConfig(data) {
    return store.dispatch({
        type: reduxConstants.SET_DATA,
        config: data
    })
}
