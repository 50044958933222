const SocialMediaVar = [
  {
      key:'InstagramBanner',
      link: 'https://www.instagram.com/radyod104/',
      img:'instagrambanner',
      img_mobile:'instabannermobil',
      img_tablet:'instabannertablet',
      alt:'Instagram',
  },
  {
    key:'TwitterBanner',
    link: 'https://twitter.com/radyod104',
    img:'twitterbanner',
    img_mobile:'twitterbannermobil',
    img_tablet:'twitterbannertablet',
    alt:'Twitter',
},
{
    key:'FacebookBanner',
    link: 'https://www.facebook.com/radyod104',
    img:'facebookbanner',
    img_mobile:'facebookbannermobil',
    img_tablet:'facebookbannertablet',
    alt:'Facebook',
},
{
    key:'youtubeBanner',
    link: 'https://www.youtube.com/@radyod104',
    img:'youtubebanner',
    img_mobile:'youtubebannermobil',
    img_tablet:'youtubebannertablet',
    alt:'Youtube',
},
{
    key:'SpotifyBanner',
    link: 'https://open.spotify.com/show/6RUe2ahKiAVeeTW0VAtYWx',
    img:'spotifybanner',
    img_mobile:'spotifybannermobil',
    img_tablet:'spotifybannertablet',
    alt:'Spotify',
},
]
export default SocialMediaVar;