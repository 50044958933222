import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//import './uikit.scss'; 
const Logo = require('../../img/radyodlogo.png?v=2');

class Uikit extends Component {
  render() {
    return (
      <div className="uikit">
        <div className="container">
          <div className="row">
            {/* Logo */}
            <div className="col-12 uikit-row">
              <div className="uikit-logo">
                <Link to="#">
                  <img height="100" width="210" src={Logo} alt="uikit-logo" />
                </Link>
              </div>
            </div>
            {/* Colours */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Colours</h1>
                  </div>
                  <div className="col-md-9 right-content uikit-colors">
                    <div className="uikit-color gradient-blue" />
                    <div className="uikit-color color-cerulean" />
                    <div className="uikit-color color-blue-zodiac" />
                    <div className="uikit-color color-white" />
                    <div className="uikit-color color-black-pearl2" />
                    <div className="uikit-color color-caribbean-green" />
                    <div className="uikit-color color-red" />
                    <div className="uikit-color color-black-caparol" />
                  </div>
                </div>
              </div>
            </div>
            {/* Cards */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Cards</h1>
                  </div>
                  <div className="col-md-9 right-content">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 left-content">
                          <h2 className="uikit-subtitle-underline left content">Standard</h2>
                        </div>
                        <div className="col-md-6 left-content">
                          <h2 className="uikit-subtitle-underline">Hover</h2>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h3 className="uikit-subtitle">Dj Cards</h3>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">card</div>
                            <div className="col-md-6">card</div>
                          </div>
                        </div>
                        <h3 className="uikit-subtitle">Program Cards</h3>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">card</div>
                            <div className="col-md-6">card</div>
                          </div>
                        </div>
                        <h3 className="uikit-subtitle">Video Card</h3>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">card</div>
                            <div className="col-md-6">card</div>
                          </div>
                        </div>
                        <h3 className="uikit-subtitle">Photo Cards</h3>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">card</div>
                            <div className="col-md-6">card</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Album Cover */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Album Cover</h1>
                  </div>
                  <div className="col-md-9 right-content">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 left-content">
                          <h2 className="uikit-subtitle-underline left content">Standard</h2>
                        </div>
                        <div className="col-md-6 left-content">
                          <h2 className="uikit-subtitle-underline">Hover</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Buttons */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Buttons</h1>
                  </div>
                  <div className="col-md-9 right-content">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 left-content">
                          <h2 className="uikit-subtitle-underline left content">Standard</h2>
                          <div className="container">
                            <div className="row">
                              <div className="col-12 uikit-element">
                                <button className="ui-button outline">
                                  Tüm Yayın Akışı <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                              <div className="col-12 uikit-element">
                                <button className="ui-button primary">
                                  <i className="fa fa-play" /> Son Podcasti Dinle!
                                </button>
                              </div>
                              <div className="col-12 uikit-element">
                                <button className="ui-button square">
                                  <i className="fa fa-play" /> Sonraki Gün
                                </button>
                              </div>
                              <div className="col-12 uikit-element">
                                <button className="ui-button label">
                                  Tümünü Gör <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                              <div className="col-12 uikit-element">
                                <button className="ui-button share">
                                  <i className="fas fa-share-square" /> Paylaş
                                </button>
                              </div>
                              <div className="col-12 uikit-element">
                                <i className="ui-button icon icon-play"></i>
                              </div>
                              <div className="col-12 uikit-element">
                                <i className="ui-button icon icon-stop"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 left-content">
                          <h2 className="uikit-subtitle-underline">Hover</h2>
                          <div className="row">
                            <div className="col-12 uikit-element">
                              <button className="ui-button active">
                                Tüm Yayın Akışı <i className="fa fa-chevron-right" />
                              </button>
                            </div>
                            <div className="col-12 uikit-element">
                              <button className="ui-button primary">
                                <i className="fa fa-play" /> Son Podcasti Dinle!
                              </button>
                            </div>
                            <div className="col-12 uikit-element">
                              <button className="ui-button square active">
                                <i className="fa fa-play" /> Sonraki Gün
                              </button>
                            </div>
                            <div className="col-12 uikit-element">
                              <button className="ui-button label active">
                                Tümünü Gör <i className="fa fa-chevron-right" />
                              </button>
                            </div>
                            <div className="col-12 uikit-element">
                              <button className="ui-button share active">
                                <i className="fas fa-share-square" /> Paylaş
                              </button>
                            </div>
                            <div className="col-12 uikit-element">
                            <i className="ui-button icon icon-play active"></i>
                            </div>
                            <div className="col-12 uikit-element">
                            <i className="ui-button icon icon-stop active"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Main Menu */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Main Menu</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Section */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Section</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Fonts */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Fonts</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Footer</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Pagination</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* TextBox */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">TextBox</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* SelectBox */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">SelectBox</h1>
                  </div>
                </div>
              </div>
            </div>
            {/* Dropdown */}
            <div className="col-12 uikit-row">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-content">
                    <h1 className="uikit-title">Dropdown</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Uikit;
