import React, { Component } from 'react'
import Logo from "./dlogo.svg";

export default class Dlogo extends Component {
  render() {
    return (
        this.props.text === "PODCAST" ? 
        <>{this.props.text} <img src={Logo}></img></> : this.props.text
    )
  }
}
