import gql from 'graphql-tag';

export const FREQUENCY_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Id
      Url
      IxName
      Title
      Duration
      Description
      Files {
        Url
      }
    }
  }
`;

export const FREQUENCY_VARIABLES = {
  top: '1000',
  skip: '0',
  filter: "Ancestors/any(a: a/SelfPath eq '/frekanslar/')",
  q: "['Content']",
  orderBy: 'StartDate asc',
};
