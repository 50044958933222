import gql from 'graphql-tag';

export const HEADER_FOOTER_QUERY = gql`
  query getMenu($Id: String!) {
    Menu: Content(Id: $Id) {
      Id
      Template {
        Regions {
          Controls {
            IxName
            Properties {
              IxName
              Value
              Name
            }
            Controls {
              Properties {
                IxName
                Value
                Name
              }
            }
          }
          IxName
        }
      }
    }
  }
`;

export const HEADER_FOOTER_VARIABLES = { Id: '58627e58f5ac76135c99590c' };
