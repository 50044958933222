import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Top20Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section className="section section-top-series fluid">
        <h2 className="section-title">{this.props.title}</h2>
        <Link to="/top20" className="section-top-link">
          TÜMÜNÜ GÖR <i className="fa fa-chevron-right"></i>
        </Link>
        <div className="row item-row">
          {this.props.data.map((item, index) => {
            if (index < 5) {
              const artistName = item.Description.split(' - ')[0],
                songName = item.Description.split(' - ')[1],
                image = item.Files[0].Url;
              return (
                <div key={index.toString()} className="col-6 five-grid">
                  <span className="top20_card">
                    <figure className="figure">
                      <div className="img-wrap img-wrapper" style={{ backgroundImage: "url('" + image + "')" }}>                      </div>
                      <figcaption className="caption">
                        <h3 className="title">{artistName}</h3>
                        <p className="spot">{songName}</p>
                        <span className="number">{index + 1}</span>
                      </figcaption>
                    </figure>
                  </span>
                </div>
              );
            } else return false;
          })}
        </div>
      </section>
    );
  }
}

export default Top20Card;
