import { isWeb } from './helper';

let swiperParamsPodcast = {};

swiperParamsPodcast = {
  slidesPerView: 5,
  breakpoints: {
   456: {
    slidesPerView: 2.2,
    spaceBetween:10,
   },
   1024: {
    slidesPerView: 3.2,
    spaceBetween:15,
   },
   1700: {
    slidesPerView: 4,
    spaceBetween:20,
   }  
  },
  slidesPerGroup: 4,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-navigation-next',
    prevEl: '.swiper-navigation-prev',
  },
};

export default swiperParamsPodcast;
