import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import trLocale from 'moment/locale/tr';
import { calculateDurationDisplay } from '../../common/helper';
moment.updateLocale('tr', trLocale);

class VideoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        key={this.props.index}
        onClick={this.props.onChangeNews !== undefined ? () => this.props.onChangeNews(this.props.url) : undefined}
        className="swiper-slide"
      >
        <Link to={'/video' + this.props.url} className={`news-card-v2 mobile-horizontal ${this.props.mobile ? "mobile" : ""}`} title={this.props.title}>
          <figure className="figure">
            <div className="img-wrap">
              <span className="bg-image" style={{ backgroundImage: "url('" + this.props.imagePath + "')" }}></span>
              <i className="ico video"></i>
              <span className="flag-dark">
                {
                  calculateDurationDisplay(this.props.duration)
                }
              </span>
            </div>
          </figure>
          <p className="spot">{this.props.title}</p>
        </Link>
      </div>
    );
  }
}

export default VideoCard;
