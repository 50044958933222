import React from "react";

const searchInput = props => (
    <form action="" onSubmit={props.submit} className="popup-search-form">
        <input
            type="text"
            className="popup-search-input"
            name="search" 
            autoComplete="off"
            placeholder="Sitede ara..."
            onChange={props.onChange}
            value={props.value}
        />
        <button type="submit" className="popup-search-btn"/>
    </form>
)

export default searchInput;
