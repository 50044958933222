import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';
import { Helmet } from 'react-helmet';
import live from '../../img/live.svg';

import { GET_PROGRAMLIST_QUERY, GET_PROGRAMLIST_VARIABLES } from '../../query/programlist';

class Program extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonStatus: null,
      personName: ""
    };
  }

  componentDidMount() {
    try {
      fetch(this.props.config.LiveRadioInfoUrl).then(res => res.json())
      .then(data => {
        this.setState({personName: data?.ProgramName});
      })
    } catch (error) {
      console.error(error)
    }
  }

  getLiveContent = (title) => {
      let personName = this.state.personName.substring(0,3).toLocaleLowerCase();
      let personName2 = title?.substring(0,3).toLocaleLowerCase();
      return personName === personName2
  }

  goToDetailPage = (user) => {
    this.props.history.push(`/program/${user?.IxName}`);
  }

  programList() {
    return (
      <Query variables={GET_PROGRAMLIST_VARIABLES} query={GET_PROGRAMLIST_QUERY}>
        {({ data, loading, error }) => {
          if (loading || !data) {
            return <Loading />;
          } else if (error) console.error(error);
          else
            return data.Filter.map((item, index) => {
              let isLive = this.getLiveContent(item.Title);
              return (
                <div key={index} className="col-12 col-md-6 program-element" onClick={() => this.goToDetailPage(item)}>
                  <div className={`new-card default${isLive ? " live" : ""}`}>
                    <figure className="figure">
                      <div className="img-wrap">
                        <img src={item.Files[0].Url} alt={item.Title} />
                      </div>
                      {isLive &&
                          <figcaption className="caption">
                            <img src={live} alt="LIVE" />
                            <p className="live">CANLI</p>
                          </figcaption>}
                    </figure>
                    <div className="content">
                      <h3>{item.Title}</h3>
                      {item.Schedule &&
                          <div className="time">
                            <p className="text">{item.Schedule}</p>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              );
            });
        }}
      </Query>
    );
  }

  render() {
    return (
      <>
      <Helmet>
        <title>Programlar - Radyo D</title>
        <meta name='description' content="Programlar sayfasında Radyo D'de yayınlanan tüm Programcıları bulabilirsiniz."></meta>
      </Helmet>
      <div className="program-container">
        <div className="row">{this.programList()}</div>
      </div>
      </>

    );
  }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const programCard = withRouter(props => <Program {...props} />)
const programCardConnect = connect(mapStateToProps)(programCard);

export default programCardConnect;