import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';
import { GET_CURRENT_TOP20_QUERY, GET_CURRENT_TOP20_VARIABLES, GET_TOP20_DETAIL_QUERY } from '../../query/top20'
import RadyodPlayer from '../../components/player/RadyodPlayer';
import { setPlayingState } from '../../redux/radio/action';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

class Top20 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePlayerNumber: null,
    };
  }

  onClickPlayButton = (itemNumber) => {
    this.setState({ activePlayerNumber: itemNumber });
    var livePlayer = document.getElementsByClassName("vjs-play-control vjs-playing");
    livePlayer.length > 0 && livePlayer[0].click();
  }

    pushVideoToBdmp = (data, item, e) => {        
        window.bdmp('video', {
            'videoIdentifier': item.MediaFiles[0].Path,
            'videoLabel': item.Description,
            'videoPosterUrl': item.Files[0].Url,
            'videoPublishedDate': Date.parse(data.Content.StartDate) || null,
            'videoDuration': e.duration,
            'videoLiveStream': e.isLive
        });
        if (e.playing) {            
            window.bdmp('videoTrack', {
                'videoIdentifier': item.MediaFiles[0].Path,
                'videoCurrentTime': e.progress.playedSeconds
            });
        }
    }

  render() {
    return (
      <>        
        <Query variables={GET_CURRENT_TOP20_VARIABLES} query={GET_CURRENT_TOP20_QUERY}>
        {({ data, loading, error }) => {
          if (loading || !data) {
            return <Loading />;
          } else if (error) {
            return console.error(error);
          }                    
          return (
            <Query variables={{Id: data.Filter[0].Id}} query={GET_TOP20_DETAIL_QUERY}>
              {({ data, loading, error }) => {
                if (loading || !data) {
                  return <Loading />;
                } else if (error) {
                  return console.error(error);
                }
                var title = data.Content.Title;
                var description = data.Content.Description;                                      
                return (
                  <div className="top20-container">
                    <Helmet>
                        <title>Radyo D Top 20 Listesi - En Çok Dinlenen Şarkı ve Müzikler - Radyo D</title>
                        <meta name='description' content="Radyo D Top 20 sayfasında Radyo D'de en çok sevilen ve dinlenen şarkı ve müziklerin listesini bulabilirsiniz."></meta>
                        <meta property="creators" content={data.Content.CreatedBy} />
                    </Helmet>
                    <div className="summary-text">
                      <h1 className="title cat-title">{title}</h1>
                      <p className="desc">{description}</p>
                    </div>
                    <div className="section section-top-series fluid">
                      <div className="row">
                        {data.Content.Similars.map((item, index) => {
                          var artistName = item.Description.split(' - ')[0];
                          var songName = item.Description.split(' - ')[1];
                          var image = item.Files[0].Url;
                          if (index < 5) {
                            return (
                              <div key={index} className="col-6 five-grid">
                                <section className="top20_card">
                                  <figure className="figure">
                                    <div onClick={() => { this.setState({ activePlayerNumber: this.state.activePlayerNumber === index ? null : index }) }} className="img-wrap" style={{ backgroundImage: 'url(' + image + ')' }}>
                                      {
                                        (item.MediaFiles.length > 0)&&
                                        <>
                                          <RadyodPlayer
                                            onClickPlay={(e) => {
                                                setPlayingState({
                                                    page: 'top20',
                                                    item: this.props.config.MediaUrl + item.MediaFiles[0].Path + "?part=4-12",
                                                    index: index
                                                })
                                                this.pushVideoToBdmp(data, item, e)
                                            }}
                                            playing={this.props.playingState.page === 'top20' && this.props.playingState.index === index}
                                            onlyButton
                                            size={'md'}
                                            url={this.props.config.MediaUrl  + item.MediaFiles[0].Path + "?part=4-12"}
                                            onEnded={(e) => { console.log('onEnded', e) }}
                                            onProgress={(e) => { this.pushVideoToBdmp(data, item, e) }}
                                          />
                                        </>
                                      }
                                    </div>
                                    <figcaption className="caption">
                                      <h3 className="title">{artistName}</h3>
                                      <p className="spot">{songName}</p>
                                      <span className="number">{index + 1}</span>
                                    </figcaption>
                                  </figure>
                                </section>
                              </div>
                            );
                          } else return null;
                        })}
                      </div>
                    </div>
                    <div className="top20-list">
                      {data.Content.Similars.map((item, index) => {
                        var artistName = item.Description.split(' - ')[0];
                        var songName = item.Description.split(' - ')[1];
                        var image = item.Files[0].Url;
                        if (index >= 5) {
                          return (
                            <div key={index} className="top20-list-item">
                              <img height="55" width="55" src={image} alt={artistName} />
                              <figcaption className="caption">
                                <span className="number">{index + 1}</span>
                                <div className="song-description">
                                  <h2 className="artist-name">
                                    {artistName}
                                  </h2>
                                  <span className="song-name">{songName}</span>
                                </div>
                              </figcaption>
                              {
                                (item.MediaFiles.length > 0) &&
                                <>
                                  <div className="top20-player-wrapper">
                                    <RadyodPlayer
                                      onClickPlay={(e) => {
                                          setPlayingState({
                                            page: 'top20',
                                            item: this.props.config.MediaUrl + item.MediaFiles[0].Path + "?part=4-12",
                                            index: index
                                          });
                                          this.pushVideoToBdmp(data, item, e)
                                      }}
                                      playing={this.props.playingState.page === 'top20' && this.props.playingState.index === index}
                                      onlyButton
                                      size={'md'}
                                      url={this.props.config.MediaUrl + item.MediaFiles[0].Path + "?part=4-12"}
                                      onProgress={(e) => { this.pushVideoToBdmp(data, item, e) }}
                                    />
                                  </div>
                                </>
                              }
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  </div>
                );
              }}
            </Query>
          );
        }}
        </Query>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const top20 = withRouter(props => <Top20 {...props} />)
const Top20WithRouter = connect(mapStateToProps)(top20);

export default Top20WithRouter;
