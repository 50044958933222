import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { APP_FORM_VARIABLES, APP_FORM_QUERY } from '../../query/appform'
import Loading from '../../components/loading/loading'
import HtmlParse from 'html-react-parser'
import { Helmet } from 'react-helmet';

class AppFormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="static-page-container">
        <Query variables={APP_FORM_VARIABLES} query={APP_FORM_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />
            } else if (error) console.error(error)
            else {
              return (
                <div className="about-container">
                  <Helmet>
                    <title>{data.SuperContent.Title} - Radyo D</title>
                  </Helmet>
                  <h2>{data.SuperContent.Title}</h2>
                  {HtmlParse(data.SuperContent.Text)}
                </div>
              )
            }
          }}
        </Query>
      </div>
    )
  }
}

export default AppFormPage
