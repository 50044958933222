import gql from 'graphql-tag';

export const GET_HOMEPAGE_QUERY = gql`
  query getSuperContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Id
      Url
      IxName
      Template {
        IxName
        Regions {
          IxName
          Controls {
            IxName
            Properties {
              Value
              IxName
            }
            ContentViews {
              Id
              Title
              Description
              DefaultUrl
              Files {
                Id
                FileName
                Url(Q: 100, W: 0, H: 0)
                Application
              }
              ModifiedDate
            }
            DataSource {
              Id
              IxName
              Name
              Contents {
                Title
                OriginalName
                Url
                ContentType
                Ancestors {
                  IxName
                }
                Files {
                  Id
                  FileName
                  Url(Q: 100, W: 0, H: 0)
                  Application
                }
                Duration
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_HOMEPAGE_VARIABLES = {
  url: '/anasayfa-v2',
};

//podcast
export const GET_HOMEPAGEPROGRAM_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Ancestors {
        Title
      }
      Id
      Url
      IxName
      Title
      Duration
      Description
      Files {
        Url
      }
      Relations
      Schedule
    }
  }
`;

export const GET_HOMEPAGEPROGRAM_VARIABLES = {
  top: '8',
  skip: '0',
  filter: "Ancestors/any(a: a/SelfPath eq '/program-podcast/') and ContentType eq 'TVShowContainer'",
  q: "['Clip']",
  orderBy: 'StartDate desc',
};

//top20
export const GET_HOMETOP20_QUERY = gql`
  query getSuperContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Title
      Id
      Url
      CreatedBy
      StartDate
      Similars {
        Description
        Files {
          Id
          Url(Q:75,W:500,H:500) 
        }
      }
    }
  }
`;

export const GET_HOMETOP20_VARIABLES = {
  url: '/playlist/top-20-listesi',
};

export const GET_CURRENT_HOMETOP20_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Id
    }
  }
`;

export const GET_CURRENT_HOMETOP20_VARIABLES = {
  top: '1',
  skip: '0',
  filter: "Ancestors/any(a: a/SelfPath eq '/playlist/')",
  q: "['PlayList']",
  orderBy: 'StartDate desc',
};

export const GET_HOMETOP20_DETAIL_QUERY = gql`
  query getContentById($Id: String!) {
    Content(Id: $Id) {
      Title
      Id
      Url
      IxName
      CreatedBy
      StartDate
      Similars {
        Description
        Files {
          Id
          Url(Q:75,W:500,H:500) 
        }
      }
    }
  }
`;