import gql from 'graphql-tag';

export const BROADCAST_QUERY = gql`
  query {
    EPG {
      Id
      Date
      Items {
        StartTime
        EndTime
        Title
        Url
        Files {
          Url(Q: 75, W: 350, H: 210)
        }
      }
    }
  }
`;

export const BROADCAST_VARIABLES = {};
