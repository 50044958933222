import React, { Component } from 'react'
import { Router, Switch, Route, withRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'

//css
import './styles/App.scss'

// import layouts
import DefaultLayout from './layouts/default_layout'
import FutileLayout from './layouts/futile_layout'
//Uikit page
import UikitPage from './components/uikit/uikit'

// import modules
import {
    Homepage,
    Programpage,
    Podcastdetail,
    Broadcastpage,
    Frequencypage,
    Top20page,
    Newsgallerypage,
    Newsdetail,
} from './modules'
import AboutPage from './modules/staticpages/about'
import AddWebsitePage from './modules/staticpages/addwebsite'
import AnnouncePage from './modules/staticpages/announce'
import ContactPage from './modules/staticpages/contact'
import DataPolicyPage from './modules/staticpages/datapolicy'
import ContactContentPage from './modules/staticpages/contactcontent'
import GeneralContentPage from './modules/staticpages/generalcontent'
import AppFormPage from './modules/staticpages/appform'
import AudienceRepresentative from './modules/audiencerepresentative/audiencerepresentative'
import CookiePolicyPage from './modules/staticpages/cookiepolicy'
import Page404 from './common/404'
import IFrameLarge from './components/iframe/iframe_large'
import IFrameSmall from './components/iframe/iframe_small'
import VideoGallery from './modules/videogalery/videogallery'
import VideoDetail from './modules/videodetail/videodetail'
import SearchPage from './modules/searchpage/searchpage'
import Podcast from "./modules/programpodcast/podcast";
import Events from './modules/events/events'
import ResumeDetail from './modules/programpodcast/resume'

// create new history for paths
const history = createBrowserHistory()

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            Layout == null ? (
                <Component {...props} />
            ) : (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }
    />
)

class App extends Component {
    async componentDidMount() {
        history.listen((location, action) => {
            //Sayfa her route olduğunda yani url değiştiğinde scroll sıfırlanıyor. Podcast play hariç
            if (location.state == null || !location.state.podcastPlay) window.scrollTo(0, 0)
        })
    }
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <AppRoute exact path="/" component={Homepage} layout={DefaultLayout} />
                    <AppRoute path="/program" exact component={Programpage} layout={DefaultLayout} />
                    <AppRoute path="/program/:id" component={ResumeDetail} layout={DefaultLayout} />
                    <AppRoute path="/podcast/:id" component={Podcastdetail} layout={DefaultLayout} />
                    <AppRoute path="/podcast" component={Podcast} layout={DefaultLayout} />
                    <AppRoute path="/yayin-akisi" component={Broadcastpage} layout={DefaultLayout} />
                    <AppRoute path="/frekans" component={Frequencypage} layout={DefaultLayout} />
                    <AppRoute path="/top20" component={Top20page} layout={DefaultLayout} />
                    <AppRoute path="/haber-galeri" component={Newsgallerypage} layout={DefaultLayout} />
                    <AppRoute path="/uikit" component={UikitPage} layout={FutileLayout} />
                    <AppRoute path="/izleyici-temsilcisi" component={AudienceRepresentative} layout={DefaultLayout} />
                    <AppRoute path="/ara/:id" component={SearchPage} layout={DefaultLayout} />


                    <AppRoute path="/haber/haberler/:id" component={Newsdetail} layout={DefaultLayout} />
                    <AppRoute path="/haberler/:id" component={Newsdetail} layout={DefaultLayout} />
                    <AppRoute path="/muzik-haberler/:id" component={Newsdetail} layout={DefaultLayout} />
                    <AppRoute path="/fotogaleri/foto-galeriler/:id" component={Newsdetail} layout={DefaultLayout} />
                    <AppRoute path="/fotogaleri/haberler/:id" component={Newsdetail} layout={DefaultLayout} />

                     {/* Video Gallery */}
                    <AppRoute path="/video-galeri" component={VideoGallery} layout={DefaultLayout} />
                    <AppRoute path="/videolar/:id" component={VideoDetail} layout={DefaultLayout} />
                    <AppRoute path="/video/:id" component={VideoDetail} layout={DefaultLayout} />

                     {/* Events */}
                    <AppRoute path="/etkinlikler" component={Events} layout={DefaultLayout} />

                    {/* Static Pages */}
                    <AppRoute path="/hakkimizda" component={AboutPage} layout={DefaultLayout} />
                    <AppRoute path="/sitene-ekle" component={AddWebsitePage} layout={DefaultLayout} />
                    <AppRoute path="/reklam" component={AnnouncePage} layout={DefaultLayout} />
                    <AppRoute path="/iletisim" component={ContactPage} layout={DefaultLayout} />
                    <AppRoute path="/kisisel-verilerin-korunmasi" component={DataPolicyPage} layout={DefaultLayout} />
                    <AppRoute path="/kisisel-verilerin-korunmasi-app" component={DataPolicyPage} layout={DefaultLayout} />
                    <AppRoute path="/genel-aydinlatma-metni" component={GeneralContentPage} layout={DefaultLayout} />
                    <AppRoute path="/iletisim-aydinlatma-metni" component={ContactContentPage} layout={DefaultLayout} />
                    <AppRoute path="/ilgili-kisi-basvuru-formu" component={AppFormPage} layout={DefaultLayout} />
                    <AppRoute path="/cerez-politikasi" component={CookiePolicyPage} layout={DefaultLayout} />

                    {/* IFrames */}
                    <AppRoute path="/iframe-large" component={IFrameLarge} />
                    <AppRoute path="/iframe-small" component={IFrameSmall} />

                    <AppRoute path="*" component={Page404} />
                </Switch>
            </Router>
        )
    }
}

export default withRouter(App)
