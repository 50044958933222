import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import trLocale from 'moment/locale/tr';
moment.updateLocale('tr', trLocale);

class SocialMediaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        key={this.props.index}
        onClick={this.props.onChangeNews !== undefined ? () => this.props.onChangeNews(this.props.url) : undefined}
        className="swiper-slide autocard"
      >
        <Link to={this.props.url} className={`news-card-v2 mobile-horizontal ${this.props.mobile ? "mobile" : ""}`} title={this.props.title}>
          <figure className="figure">
            <div className="img-wrap">
              <span className="bg-image" style={{ backgroundImage: "url('" + this.props.imagePath + "')" }}></span>
            </div>
          </figure>
          <span className='content'>
            TAKİP ET <i class="fa fa-chevron-right"></i>
          </span>
        </Link>
      </div>
    );
  }
}

export default SocialMediaCard;
