import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { isWeb, isInternalUrl } from './../../../common/helper'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'

class Manset extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSlideIndex: 0
        }
    }

    componentDidMount() {
        // manset slider
        const swiperElement = document.querySelector('.swiper-manset-v1')
        if (!swiperElement) return false

        const paginationContainer = swiperElement.querySelector('.pagination-container'),
            thumbnailsContainer = paginationContainer.querySelector('.pagination-thumbnails'),
            thumbnails = paginationContainer.querySelectorAll('.bg'),
            progressBar = paginationContainer.querySelector('.pagination-progress-bar'),
            nextTitle = paginationContainer.querySelector('.pagination-next-title')

        const swiperManset = new Swiper(swiperElement, {
            lazy: {
                loadOnTransitionStart: true,
                loadPrevNextAmount: 1,
            },
            loop: true,
            init: false,
            autoplay: {
                delay: 1000 * 10, // 10 seconds
                disableOnInteraction: false,
            },
            pagination: {
                el: swiperElement.querySelector('.swiper-pagination'),
                clickable: true,
            },
            navigation: {
                nextEl: swiperElement.querySelector('.swiper-button-next'),
                prevEl: swiperElement.querySelector('.swiper-button-prev'),
            },
        })

        swiperManset.on('init', () => {
            if (thumbnails.length < 2) {
                thumbnailsContainer.remove()
                paginationContainer.remove()
            } else {
                let secondThumbnail = thumbnails[1]
                nextTitle.innerText = secondThumbnail.getAttribute('data-title') // show 2nd thumbnail's title
                if (isWeb()) {
                    const itemPath = ' background-image: url(' + secondThumbnail.getAttribute('data-src') + ');'
                    secondThumbnail.setAttribute('style', itemPath)
                    secondThumbnail.classList.add('show') // show 2nd thumbnail
                    progressBar.innerHTML = `<span style="animation-duration: ${this.autoPlayIntervalMansetSwiperV1}ms"/>` // start progress bar
                    thumbnailsContainer.addEventListener('click', () => {
                        // add click event to thumbnail
                        swiperManset.slideNext()
                    })
                }
            }
        })

        swiperManset.init()

        swiperManset.on('slideChange', (e) => {
            let activeSlideIndex = swiperManset.realIndex;
            this.setState({ activeSlideIndex: swiperManset.realIndex });
            thumbnails.forEach((thumbnail, index) => {
                if (activeSlideIndex + 1 === index) {
                    if (isWeb()) {
                        const itemPath = ' background-image: url(' + thumbnail.getAttribute('data-src') + ');'
                        if (!thumbnail.hasAttribute('style')) thumbnail.setAttribute('style', itemPath)
                        thumbnail.classList.add('show') // show next slide's thumbnail
                    }
                    nextTitle.innerText = thumbnail.getAttribute('data-title')
                } else if (isWeb()) {
                    thumbnail.classList.remove('show') // hide all others
                }
            })

            // Check if the active is the last slide
            if (activeSlideIndex + 1 === thumbnails.length) {
                let firstThumbnail = thumbnails[0]
                if (isWeb()) {
                    const itemPath = ' background-image: url(' + firstThumbnail.getAttribute('data-src') + ');'
                    firstThumbnail.setAttribute('style', itemPath)
                    firstThumbnail.classList.add('show')
                }
                nextTitle.innerText = firstThumbnail.getAttribute('data-title')
            }

            // Restart progress bar
            if (isWeb())
                progressBar.innerHTML = `<span style="animation-duration: ${this.autoPlayIntervalMansetSwiperV1}ms"/>`
        })
    }

    render() {
        return (
            <section className="section">
                <div className="swiper-container swiper-manset-v1">
                    <div className="swiper-wrapper">
                        {this.props.data.map((item, index) => {
                            return (
                                isInternalUrl(item.DefaultUrl)
                                ?<Link
                                    key={index}
                                    className="swiper-slide"
                                    to={item.DefaultUrl}
                                    data-shadow={item.Title === '' ? 'none' : null}
                                >
                                    <div
                                        style={{ backgroundImage: 'url(' + item.Files[0].Url + ')' }}
                                        className="swiper-slide-bg"
                                    ></div>
                                    <div className="captions-title">{item.Title}</div>
                                </Link>
                                : < a
                                    key={index}
                                    target="_blank"
                                    rel = "noopener noreferrer"
                                    className="swiper-slide"
                                    href={item.DefaultUrl} 
                                    data-shadow={item.Title === '' ? 'none' : null}
                                >
                                    <div
                                        style={{ backgroundImage: 'url(' + item.Files[0].Url + ')' }}
                                        className="swiper-slide-bg"
                                    ></div>
                                    <div className="captions-title">{item.Title}</div>
                                </a>
                            )
                        })}
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-thumbnails">
                            {this.props.data.map((item, index) => {
                                return <div key={index} data-src={item.Files[0].Url} className="bg"></div>
                            })}
                            <div className="pagination-next-container">
                                {/* <div className="pagination-next-label">SIRADAKİ</div> */}
                                <div className="pagination-next-title"></div>
                            </div>
                            <div className="pagination-progress-bar"></div>
                        </div>
                        <div className="pagination-swiper-pagination swiper-pagination"></div>
                    </div>
                </div>
                {!isWeb() && (
                    <div className="mobile-manset-pagination">
                        <div className="pagination-swiper-pagination swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                            {this.props.data.map((item, index) => {
                                return (
                                    <span
                                        key={index}
                                        className={
                                            index === this.state.activeSlideIndex
                                                ? 'swiper-pagination-bullet active'
                                                : 'swiper-pagination-bullet'
                                        }
                                        tabIndex={index}
                                        role="button"
                                        aria-label={'Go to slide ' + (index + 1)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )}
            </section>
        )
    }
}

export default Manset
