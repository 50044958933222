
import { Component } from 'react';


class Uikit extends Component {

    render() {
        const { children } = this.props;
        return children
    }
}


export default Uikit



