import React, { Component } from 'react'
import vectorMapData from './vertormap-tr.json'
import './vectormap.scss'

export default class VectorMap extends Component {

    constructor(props){
        super(props)
        this.state = {
            isHover: false,
            center: null,
            cityName: '',
            frequency: ''
        }
    }

    onMouseEnter = (layer) => {
        const coordinates = layer.d.replace('m ', '').split('m')[0].replace(' z', '').split(' ')
        const started = coordinates[0].split(',')
        const stopCount = Math.round((coordinates.length - 1) / 2)
        const opposite = [+started[0], +started[1]]

        for (let i = 1; i < stopCount; i++) {
            opposite[0] += +coordinates[i].split(',')[0]
            opposite[1] += +coordinates[i].split(',')[1]
        }

        const x = (+started[0] + opposite[0]) / 2
        const y = (+started[1] + opposite[1]) / 2
        let center = { x, y }

        let cityName = ''
        let frequency = ''
        const selectedCity = this.props.data.find(p => p.name === layer.ixName)
        if(selectedCity !== undefined){
            cityName = layer.name
            frequency = selectedCity.value
        } else 
            center = null

        this.setState({ center, cityName, frequency })
    }

    render() {
        return (
            <div className="vectormap-container">
                < svg 
                    version = "1.1"
                    id = {vectorMapData.id}
                    xmlns = "http://www.w3.org/2000/svg"
                    xlink = "http://www.w3.org/1999/xlink"
                    viewBox = {vectorMapData.viewBox}
                    space = "preserve"
                >
                    {
                        vectorMapData.layers.map((layer, index) => {
                            return (
                                <g
                                    key={index}
                                    onMouseEnter={(e) => this.onMouseEnter(layer)}
                                    id={layer.id}
                                    stroke={'#031424'}
                                >
                                    <path d={layer.d}></path>
                                </g>
                            )
                        })
                    }
                    {
                        this.state.center != null && 
                        <>
                            <circle cx={this.state.center.x} cy={this.state.center.y} r={5} style={{ stroke: '#055E95', fill: '#055E95'}} />
                            <line x1={this.state.center.x} y1={this.state.center.y} x2={325} y2={370} style={{ stroke:'#055E95',strokeWidth:1}} />
                            <line x1={325} y1={370} x2={350} y2={370} style={{ stroke:'#055E95',strokeWidth:1}} />
                            <circle cx={350} cy={370} r={5} style={{ stroke: '#055E95', fill: '#0F2C46' }}  />
                            <text id='cityName' x={365} y={355} fontSize={16} fontWeight={500} fill="white">{this.state.cityName}</text>
                            <text id='frequency' x={365} y={375} fontSize={21} fontWeight={800} fill="white">{this.state.frequency}</text>
                        </>
                    }
                </svg>
            </div>
        )
    }
}
