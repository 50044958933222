import React, { Component } from 'react'
import { Query } from 'react-apollo'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CustomSwiper from '../../common/customswiper'
import SwiperLoading from '../../common/swiperloading'
import { SeeAllCard } from '../../components/card'
import VideoCard from '../../components/card/videocard'
import Loading from '../../components/loading/loading'
import { VIDEO_DETAIL_QUERY } from '../../query/videodetail'
import { VIDEOGALLERY_QUERY } from '../../query/videogallery'
import { setPlayingState } from '../../redux/radio/action'
import { Helmet } from 'react-helmet'

class VideoDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            collapseActive: true,
            playing: false
        }
    }

    onClickPlay = (url) => {
        setPlayingState({
            page: 'videoPage',
            item: url,
            index: 0
        })
        this.setState({ playing: true })
    }

    render() {
        const contentUrl = this.props.location.pathname.replace('/video/', '/')
        return (
            <div className='video-page-container'>
                <Query variables={{ url: contentUrl }} query={VIDEO_DETAIL_QUERY}>
                    {({ data, loading, error }) => {
                        if (loading || !data) {
                            return <Loading />;
                        } else if (error) console.error(error);
                        else {
                            const url = data.SuperContent.MediaFiles.length === 0 ? '' : data.SuperContent.MediaFiles[0].Url
                            return (
                                <>
                                    <Helmet>
                                        <title>{data.SuperContent.Title} - Radyo D</title>
                                        <meta name='description' content={data.SuperContent.Description}></meta>
                                    </Helmet>
                                    <div className="player-wrapper">
                                        <ReactPlayer
                                            onPlay={() => this.onClickPlay(url)}
                                            onPause={() => this.setState({ playing: false })}
                                            onEnded={() => this.setState({ playing: false })}
                                            className="player"
                                            playing={this.props.playingState.page === 'videoPage' && this.state.playing}
                                            config={{ file: { forceHLS: true } }}
                                            controls
                                            url={url}
                                        />
                                    </div>
                                    <div className="collapse-item">
                                        <div onClick={() => this.setState({ collapseActive: !this.state.collapseActive })} className="collapse-header">
                                            <h3>{data.SuperContent.Title}</h3>
                                            {
                                                this.state.collapseActive
                                                    ? <i className="fa fa fa-chevron-up"></i>
                                                    : <i className="fa fa fa-chevron-down"></i>
                                            }

                                        </div>
                                        {
                                            this.state.collapseActive &&
                                            <div className="collapse-body">
                                                <span> {data.SuperContent.Description} </span>
                                            </div>
                                        }
                                    </div>
                                </>
                            );
                        }
                    }}
                </Query>

                <div className="other-videos">
                    <Query
                        variables={{
                            top: 8,
                            skip: 0,
                            filter:"Ancestors/any(a: a/Id eq '60112704ebc8302e3c457faf') and Url ne '" + contentUrl + "'",
                            q: '',
                            orderBy: 'StartDate desc',
                        }}
                        query={VIDEOGALLERY_QUERY}
                    >
                        {({ data, loading, error }) => {
                            if (loading || !data) return <SwiperLoading />;
                            if (error) console.error(error);
                            else {
                                return (
                                    <section className="section fluid news-section">
                                        <h2 className="section-title">DİĞER VİDEOLAR</h2>
                                        <CustomSwiper>
                                            {data.Filter.map((item, index) => {
                                                return (
                                                    <VideoCard
                                                        key={index}
                                                        index={index}
                                                        url={item.Url}
                                                        title={item.Title}
                                                        imagePath={item.Files[0].Url}
                                                        date={item.StartDate}
                                                        duration={item.Duration}
                                                    />
                                                );
                                            })}
                                            <SeeAllCard url={'/video-galeri'} />
                                        </CustomSwiper>
                                    </section>
                                );
                            }
                        }}
                    </Query>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const videoDetail = withRouter(props => <VideoDetail {...props} />)
const VideoDetailWithRouter = connect(mapStateToProps)(videoDetail);

export default VideoDetailWithRouter;
