import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Manset, InstagramWidget } from './partial/';
import { PodcastCard, Top20Card, SeeAllCard } from './../../components/card/';
import SeeAllNewCard from '../../components/card/seeallnewcard';
import HtmlParse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import SocialMediaVar from '../../constructors/SocialMediaVar';

import {
  GET_HOMEPAGE_QUERY,
  GET_HOMEPAGE_VARIABLES,
  GET_HOMEPAGEPROGRAM_QUERY,
  GET_HOMEPAGEPROGRAM_VARIABLES,
  GET_CURRENT_HOMETOP20_QUERY,
  GET_CURRENT_HOMETOP20_VARIABLES,
  GET_HOMETOP20_DETAIL_QUERY,
} from '../../query/homepage';
import { NEWSGALLERY_VARIABLES, NEWSGALLERY_QUERY } from '../../query/newsgallery';
import { ABOUT_VARIABLES, ABOUT_QUERY } from '../../query/about';
import { GET_PODCASTDLIST_VARIABLES, GET_PROGRAMLIST_QUERY } from '../../query/programlist';

import SwiperLoading from '../../common/swiperloading';
import NewsCard from '../../components/card/newscard';
import SocialMediaCard from '../../components/card/socialmediacard';
import CustomSwiper from '../../common/customswiper';
import CustomSwiperPodcast from '../../common/customSwiperPodcast';
import Top20CardLoading from '../../common/top20CardLoading';
import PodcastArea from '../../components/card/podcastarea';
import SocialMediaSwiper from '../../common/socialmediaswiper';

const LoadingImage = require('../../img/loading-card-image.png?v=2');

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swiperload: false,
    };
  }

  render() {
    return (
      <>
      <Helmet>
        <title>Radyo D ile Canlı Radyo Dinle 📻 Online Müzik Keyfini Yaşa</title>
        <meta name='description' content="Türkçe müziğin en seçkin ve en iyi şarkılarını sunan Radyo D'yi online olarak dinlemek isterseniz radyod.com'un Canlı Radyo Dinle sayfasına girebilirsiniz."></meta>
      </Helmet>
        {/* Manşet */}
        <Query variables={GET_HOMEPAGE_VARIABLES} query={GET_HOMEPAGE_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) return <img className="manset-loading-image" src={LoadingImage} alt="radyod"></img>;
            if (error) console.error(error);
            else {
              return <Manset data={data.SuperContent.Template.Regions[3].Controls[0].ContentViews} />;
            }
          }}
        </Query>

        {/* Program Slide */}
        <section className="section fluid program-section">
          <Query variables={GET_HOMEPAGEPROGRAM_VARIABLES} query={GET_HOMEPAGEPROGRAM_QUERY}>
            {({ data, loading, error }) => {
              if (loading || !data) return <SwiperLoading />;
              if (error) console.error(error);
              else {
                return (
                  <>
                    <h2 className="section-title mb-5">{data.Filter[0].Ancestors[0].Title}</h2>
                    <CustomSwiper>
                      {data.Filter.map((item, index) => {
                        return (
                          <PodcastCard
                            key={index}
                            index={index}
                            url={'program'}
                            title={item.Title}
                            description={item.Schedule}
                            imagePath={item.Files[0].Url}
                            date={item.ModifiedDate}
                            relationCount={item.Relations.length}
                          />
                        );
                      })}
                    </CustomSwiper>
                  </>
                );
              }
            }}
          </Query>
        </section>

        {/*Podcast Slide */}
        <section className="section fluid podcast-section">
          <Query variables={GET_PODCASTDLIST_VARIABLES} query={GET_PROGRAMLIST_QUERY}>
            {({ data, loading, error }) => {
              if (loading || !data) return <SwiperLoading />;
              if (error) console.error(error);
              else {
                return (
                  <>
                    <h2 className="section-title mb-5">PODCASTLER</h2>
                    <Link to="/podcast" className="section-link">
                      TÜMÜNÜ GÖR <i className="fa fa-chevron-right"></i>
                    </Link>
                    <CustomSwiperPodcast>
                      {data.Filter.slice(0, 12).map((item, index) => {
                        return (
                          <PodcastArea
                            key={index}
                            index={index}
                            url={'/podcast/'+item.IxName}
                            title={item.Title}
                            description={item.Schedule}
                            imagePath={item.Files[0].Url}
                            date={item.ModifiedDate}
                            relationCount={item.Relations.length}
                          />
                        );
                      })}
                    </CustomSwiperPodcast>
                  </>
                );
              }
            }}
          </Query>
        </section>
        
        {/* Top20 list */}
        <Query variables={GET_CURRENT_HOMETOP20_VARIABLES} query={GET_CURRENT_HOMETOP20_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) return <Top20CardLoading />;
            if (error) console.error(error);
            else {
              return (
                <Query variables={{Id: data.Filter[0].Id}} query={GET_HOMETOP20_DETAIL_QUERY}>
                  {({ data, loading, error }) => {
                    if (loading || !data) return <Top20CardLoading />;
                    if (error) console.error(error);
                    else {
                      return <Top20Card title={data.Content.Title} data={data.Content.Similars} />;
                    }
                  }}
                </Query>
              )
            }
          }}
        </Query>

        {/* Instagram widget */}
        <Query variables={GET_HOMEPAGE_VARIABLES} query={GET_HOMEPAGE_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) return <InstagramWidget data={{
              DefaultUrl: '#',
              Title: 'Yükleniyor...',
              Description: 'Yükleniyor...',
              Files:[{
                FileName:'Radyod',
                Url:LoadingImage,
              }]
            }} />;
            if (error) console.error(error);
            else {
              return <InstagramWidget data={data.SuperContent.Template.Regions[3].Controls[1].ContentViews[0]} />;
            }
          }}
        </Query>

        <section className="section fluid socialmedia-section">
          <>
            <SocialMediaSwiper>
              {SocialMediaVar.map((item) => (
                <div
                  key={item.key}
                  className="swiper-slide autocard"
                  >
                    <a href={item.link} target="_blank">
                      <figure className="figure">
                        <div className="img-wrap">
                          <span className="bg-image">
                            <picture>
                              <source media="(max-width:414px)" srcSet={require(`../../img/${item.img_mobile}.png`)}/>
                              <source media="(max-width:850px)" srcSet={require(`../../img/${item.img_tablet}.png`)}/>
                              <img src={require(`../../img/${item.img}.png`)} alt={item.alt} />
                            </picture>
                          </span>
                        </div>
                      </figure>
                    </a>
                </div>
              ))}
            </SocialMediaSwiper>
          </>
        </section>

        <section className="section fluid news-section">
          <Query variables={NEWSGALLERY_VARIABLES} query={NEWSGALLERY_QUERY}>
            {({ data, loading, error }) => {
              if (loading || !data) return <SwiperLoading />;
              if (error) console.error(error);
              else {
                return (
                  <>
                    <h2 className="section-title">{data.Filter[0].Ancestors[0].Title}</h2>
                    <CustomSwiper>
                      {data.Filter.map((item, index) => {
                        return (
                          <NewsCard
                            key={index}
                            index={index}
                            url={item.Url}
                            title={item.Title}
                            imagePath={item.Files[0].Url}
                            date={item.StartDate}
                          />
                        );
                      })}
                      <SeeAllCard url={'/haber-galeri'} />
                    </CustomSwiper>
                  </>
                );
              }
            }}
          </Query>
        </section>

        <section className="about-container">
          <Query variables={ABOUT_VARIABLES} query={ABOUT_QUERY}>
            {({ data, loading, error }) => {
              if (loading || !data) {
                return <></>;
              } else if (error) console.error(error);
              else {
                var aboutText = data.SuperContent.Text.substr(0, 500) + ' ...';
                var aboutUrl = data.SuperContent.Url;
                return (
                  <>
                    {HtmlParse(aboutText)}
                    <Link to={aboutUrl}>
                      <button className="ui-button outline">
                        DEVAMINI GÖR <i className="fa fa-chevron-right"></i>
                      </button>
                    </Link>
                  </>
                );
              }
            }}
          </Query>
        </section>
      </>
    );
  }
}


export default Homepage;
