import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';
import HtmlParse from 'html-react-parser';
import { COOKIE_POLICY_VARIABLES, COOKIE_POLICY_QUERY } from '../../query/cookiepolicy';
import { Helmet } from 'react-helmet';

class CookiePolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="static-page-container">
        <Query variables={COOKIE_POLICY_VARIABLES} query={COOKIE_POLICY_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />;
            } else if (error) console.error(error);
            else {
              return (
                <div className="policy-container">
                  <Helmet>
                    <title>{data.SuperContent.Title} - Radyo D</title>
                  </Helmet>
                  <h2>{data.SuperContent.Title}</h2>
                  {HtmlParse(data.SuperContent.Text)}
                </div>
              );
            }
          }}
        </Query>
      </div>
    );
  }
}

export default CookiePolicyPage;
