import React, { Component } from 'react';
import { NEWS_QUERY, NEWSCOUNT_QUERY, NEWSCOUNT_VARIABLES } from '../../query/news';
import { MonthNameLocalizeToTR } from '../../common/helper';
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';
import { Link } from 'react-router-dom';
import Pagination from '../../common/pagination';
import { Helmet } from 'react-helmet';

const LoadingImage = require('../../img/loading-card-image.png?v=2');

class Newsgallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 15,
      variables: {
        top: 15,
        skip: 0,
        filter: "Ancestors/any(a: a/Id eq '57fcb9d5f5ac764c604f1edb' or a/Id eq '59bbb8e1ac1fe32fe46a81ad')",
        q: '',
        orderBy: 'StartDate desc',
      },
    };
  }

  onchangePage = selectedPage => {
    if (this.state.currentPage !== selectedPage) {
      this.setState({
        currentPage: selectedPage,
        variables: {
          top: this.state.pageSize,
          skip: (selectedPage - 1) * this.state.pageSize,
          filter: "Ancestors/any(a: a/Id eq '57fcb9d5f5ac764c604f1edb' or a/Id eq '59bbb8e1ac1fe32fe46a81ad')", 
          q: '',
          orderBy: 'StartDate desc',
        },
      });
    }
  };

  render() {
    return (
      <div className="listing-holder">
      <Helmet>
        <title>Haber Galeri - Radyo D</title>
        <meta name='description' content="Haberler sayfasında Radyo D'de yayınlanan son haberleri bulabilirsiniz."></meta>
      </Helmet>
        <Query variables={this.state.variables} query={NEWS_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />;
            } else if (error) console.error(error);
            else {
              const title = data.Filter[0].Ancestors[0].Title;
              return (
                <>
                  <h1 className="cat-title">{title}</h1>
                  <div className="row">
                    {data.Filter.map((item, index) => {
                      var date = new Date(item.StartDate);
                      var detailPath = item.Url;
                      return (
                        <div key={index} className="item col-12 col-md-4 col-lg-4">
                          <Link to={detailPath} className="news-card-v2 mobile-horizontal" title={item.Title}>
                            <figure className="figure">
                              <div className="img-wrap">
                                <span className="bg-image" style={{ backgroundImage: item.Files.length < 1 ? "url('" + LoadingImage + "')": "url('" + item.Files[0].Url + "')" }}></span>
                                <i className="ico"></i>
                                <span className="flag-dark">
                                  <i className="fa fa-calendar-alt"></i>{' '}
                                  {date.getDate() + ' ' + MonthNameLocalizeToTR(date.getMonth() + 1) + ' ' + date.getFullYear()}
                                </span>
                              </div>
                              <figcaption className="caption">
                                <p className="spot">{item.Title}</p>
                              </figcaption>
                            </figure>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                  <Query variables={NEWSCOUNT_VARIABLES} query={NEWSCOUNT_QUERY}>
                    {({ data, loading, error }) => {
                      if (loading || !data) {
                        return <Loading />;
                      } else if (error) console.error(error);
                      else {
                        const count = data.FilterCount.Count;
                        return (
                          <Pagination
                            onchangePage={this.onchangePage}
                            current={this.state.currentPage}
                            itemCount={count}
                            pageSize={this.state.pageSize}
                          />
                        );
                      }
                    }}
                  </Query>
                </>
              );
            }
          }}
        </Query>
      </div>
    );
  }
}

export default Newsgallery;
