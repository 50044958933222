import gql from 'graphql-tag';

export const VIDEOGALLERY_QUERY = gql `
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Ancestors {
        Title
      }
      Id
      Url
      IxName
      Title
      Duration
      Description
      Text
      ModifiedDate
      StartDate
      ContentType
      Files {
        Url
      }
    }
  }
`;

export const VIDEOGALLERYCOUNT_QUERY = gql `
  query filtercount($filter: String!, $q: String!, $orderBy: String!) {
    FilterCount(Filter: $filter, Q: $q, OrderBy: $orderBy) {
      Count
    }
  }
`;

export const VIDEOGALLERYCOUNT_VARIABLES = {
    filter: "Ancestors/any(a: a/Id eq '60112704ebc8302e3c457faf')",
    q: '',
    orderBy: 'StartDate desc',
};
