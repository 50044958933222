import gql from 'graphql-tag'

export const EVENTS_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Ancestors {
        Title
      }
      Id
      Url
      IxName
      Title
      Duration
      Description
      Text
      ModifiedDate
      StartDate
      ContentType
      Files {
        Url
      }
      Properties {
        IxName
        Value
        Name
      }
    }
  }
`

export const EVENTSCOUNT_QUERY = gql`
  query filtercount($filter: String!, $q: String!, $orderBy: String!) {
    FilterCount(Filter: $filter, Q: $q, OrderBy: $orderBy) {
      Count
    }
  }
`
