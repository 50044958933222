import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const LoadingImage = require(`../img/loading-card-image.png?v=2`);
const tempValue = [1,2,3,4,5];

class Top20CardLoading extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <section className="section section-top-series fluid">
            <h2 className="section-title">{this.props.title}</h2>
            <Link to="/top20" className="section-top-link">
              TÜMÜNÜ GÖR <i className="fa fa-chevron-right"></i>
            </Link>
            <div className="row item-row">
              {tempValue.map((item, index) => {
                if (index < 5) {
                  return (
                    <div key={index.toString()} className="col-6 five-grid">
                      <span className="top20_card">
                        <figure className="figure">
                          <div className="img-wrap img-wrapper" style={{ backgroundImage: "url('" + LoadingImage + "')" }}>                      
                          </div>
                          <figcaption className="caption">
                            <h3 className="title">Yükleniyor...</h3>
                            <p className="spot">Yükleniyor...</p>
                            <span className="number">{index + 1}</span>
                          </figcaption>
                        </figure>
                      </span>
                    </div>
                  );
                } else return false;
              })}
            </div>
          </section>
        );
    }
}
 
export default Top20CardLoading;