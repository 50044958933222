import gql from 'graphql-tag';

export const LIGHTBOX_QUERY = gql`
query getSuperContentByUrl($url: String!){
  SuperContent(Url:$url)
  {
    Id
    Title
    Template
    {
      Regions
      {
        Controls
        {
          IxName
          Properties
          {
            IxName
            Value
            SelectValues(Status:true)
            {
              Selected
              IxName
            }
          }
          }
      }
    }
    MediaFiles
    {
      Path
    }
    Files
    {
      Id
      Url
      Metadata
      {
        Title
        Tags
        
      }

    }
  }
}
`;

export const LIGHTBOX_VARIABLES = {
  url : '/data/lightbox-promo-ogesi'
};
