import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { GENERAL_CONTENT_VARIABLES, GENERAL_CONTENT_QUERY } from '../../query/generalcontent'
import { CONTACT_CONTENT_VARIABLES, CONTACT_CONTENT_QUERY } from '../../query/contactcontent'
import { APP_FORM_VARIABLES, APP_FORM_QUERY } from '../../query/appform'
import { COOKIE_POLICY_VARIABLES, COOKIE_POLICY_QUERY } from '../../query/cookiepolicy';
import Loading from '../../components/loading/loading'
import HtmlParse from 'html-react-parser'
import { Helmet } from 'react-helmet';

class DataPolicyPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      titles: [
        {
          name: 'Genel Aydınlatma Metni',
        },
        {
          name: 'İletişim Aydınlatma Metni',
        },
        {
          name: 'İlgili Kişi Başvuru Formu'
        },
        {
          name: 'ÇEREZ POLİTİKASI'
        },
      ],
      currentTab: 'İletişim Aydınlatma Metni',
    }
  }

  onScroll = ({ target }) => {
    if (window.innerWidth <= 1300) {
      if (target.children[target.children.length - 1].getBoundingClientRect().right > window.innerWidth) {
        target.classList.add('hover')
      } else if (target.children[target.children.length - 1].getBoundingClientRect().right < window.innerWidth) {
        target.classList.remove('hover')
      }
    }
  }

  details = () => {
    const details = Array.from(document.querySelectorAll('details'))

    details.forEach((detail) => {
      detail.addEventListener('click', (e) => {
        const active = details.find((d) => d.open)
        if (!e.currentTarget.open && active) {
          active.open = false
        }
      })
    })
  }

  onClick = title => {
    this.setState({currentTab:title})
  }

  render() {
    return (
      <div className="static-page-container">
        <div className="policy-container">
          <h2>Kişisel Verilerin Korunması</h2>
          <div className="table-bar d-flex h-100 mt-5 w-100">
            <ul className="d-flex w-100 h-100 table-list hover" onScroll={this.onScroll}>
                {this.state.titles.map((title, index) => (
                    <li
                        key={title.name}
                        className={title.name === this.state.currentTab ? "active" : ""}
                        onClick={() => this.onClick(title.name)}
                    >
                        {title.name}
                    </li>
                ))}
            </ul>
          </div>
          {this.state.currentTab === "Genel Aydınlatma Metni" ? (
          <Query variables={GENERAL_CONTENT_VARIABLES} query={GENERAL_CONTENT_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />
            } else if (error) console.error(error)
            else {
              return (
                <div className="content-container">
                  <Helmet>
                    <title>{data.SuperContent.Title} - Radyo D</title>
                  </Helmet>
                  {HtmlParse(data.SuperContent.Text)}
                </div>
              )
            }
          }}
        </Query>
        ): this.state.currentTab === "İletişim Aydınlatma Metni" ?
        (
          <Query variables={CONTACT_CONTENT_VARIABLES} query={CONTACT_CONTENT_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />
            } else if (error) console.error(error)
            else {
              return (
                <div className="content-container">
                  {HtmlParse(data.SuperContent.Text)}
                </div>
              )
            }
          }}
        </Query>

        ): this.state.currentTab === "İlgili Kişi Başvuru Formu" ?
        (
          <Query variables={APP_FORM_VARIABLES} query={APP_FORM_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />
            } else if (error) console.error(error)
            else {
              return (
                <div className="content-container">
                  {HtmlParse(data.SuperContent.Text)}
                  <div className="text-center mt-5">
                    <a className="ui-button primary d-inline-block" href="/ilgili-kisi-basvuru-formu.docx">
                      <i className="fa fa-download pl-0 pr-2"/> BAŞVURU FORMU
                    </a>
                  </div>
                </div>
              )
            }
          }}
        </Query>

        ): this.state.currentTab === "ÇEREZ POLİTİKASI" ?
        (
          <Query variables={COOKIE_POLICY_VARIABLES} query={COOKIE_POLICY_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />
            } else if (error) console.error(error)
            else {
              return (
                <div className="content-container">
                  {HtmlParse(data.SuperContent.Text)}
                </div>
              )
            }
          }}
        </Query>

        ): null}

        </div>


      </div>
    )
  }
}

export default DataPolicyPage
