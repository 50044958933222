import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Loading from '../../components/loading/loading';
import { BROADCAST_QUERY, BROADCAST_VARIABLES } from '../../query/broadcast';
import { MonthNameLocalizeToTR } from '../../common/helper';
import { Helmet } from 'react-helmet';

const days = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];

class BroadCast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      selectedDayValue: 0,
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
  }

  componentDidMount() {
    var date = new Date();
    //Note: getDay() => Sunday is 0, Monday is 1, and so on.
    var todayValue = date.getDay() === 0 ? 6 : date.getDay() - 1;
    this.setState({ selectedDayValue: todayValue });
  }

  showDropdownMenu = event => {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  };

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  };

  onClickNextDay = () => this.setState({ selectedDayValue: this.state.selectedDayValue === 6 ? 0 : this.state.selectedDayValue + 1 });

  isActiveProgram = (start, end) => {
    var now = new Date();
    
    //Backend'den gelen UTC probleminden dolayı yapılan düzenleme. Local saate göre gelince bu satır silinmeli
    now = new Date(now.getFullYear(),now.getMonth(),now.getDate(),now.getHours() + 3, now.getMinutes());

    return new Date(start) < now && now < new Date(end);
  };

  onchangeDays = selectedDayValue => this.setState({ selectedDayValue });

  render() {
    return (
      <>
        <Helmet>
        <title>Yayın Akışı - Haftalık Yayın Akışı - Radyo D</title>
        <meta name='description' content="Radyo D Yayın Akışı sayfasında günlük ve haftalık yayın akışı bölümlerini ve programlarını öğrenebilirsiniz."></meta>
      </Helmet>
      <Query query={BROADCAST_QUERY} variables={BROADCAST_VARIABLES}>
        {({ data, loading, error }) => {
          if (loading || !data) {
            return <Loading></Loading>;
          } else if (error) {
            return <div>Gql Err!</div>;
          } else {
            var date = new Date(data.EPG[this.state.selectedDayValue].Date);
            var localizeDate = date.getDate() + ' ' + MonthNameLocalizeToTR(date.getMonth() + 1) + ' ' + date.getFullYear();
            return (
              <div className="broadcast-container">
                <div className="broadcast-day">
                  <h2 className="cat-title">
                    {localizeDate} - {days[date.getDay() === 0 ? 6 : date.getDay() - 1]}
                  </h2>
                  <div className="broadcast-dropdown rd-dropdown">
                    <button onClick={this.showDropdownMenu} className={this.state.displayMenu ? 'select-day active' : 'select-day'}>
                      Gün Seç <i className="fa fa-caret-down"></i>
                    </button>
                    {this.state.displayMenu ? (
                      <div className="breadcrumb-sub-nav rd-dropdown-list">
                        {days.map((item, index) => {
                          return (
                            <button
                              key={index}
                              value={index}
                              onClick={e => this.onchangeDays(+e.target.value)}
                              type="button"
                              // eslint-disable-next-line eqeqeq
                              className={this.state.selectedDayValue == index ? 'link active' : 'link'}
                            >
                              {item}
                            </button>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="broadcast-list">
                  {data.EPG[this.state.selectedDayValue].Items.map((item, index) => {
                    const date = new Date(item.StartTime);
                    //CMS'den gelen veri GMT+3 olmasına rağmen kayıt esnasında utc gibi davrandığı için 
                    //cms de eklenen veriye 3 saat (Türkiye timezonu) ekliyor. Her program var olandan 3
                    //saat sonra başlayacakmış gibi görünüyor. Bu sorunu cms tarafında çözmek gerekiyor fakat
                    //burada gelen saat bilgisini tekrar utc ye çevirdiğimizde sorunu geçiçi olarak çözmüş oluyoruz
                    //sorun cms'den düzeldiğinde lütfen getUTCHours() metodunu getHours() olarak değiştirin ve bu comment'i silin
                    const startTime =
                      (date.getUTCHours() < 10 ? '0' : '') + date.getUTCHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
                    return (
                      <div
                        key={index}
                        className={
                          this.isActiveProgram(item.StartTime, item.EndTime) ? 'broadcast-list-item active' : 'broadcast-list-item'
                        }
                      >
                        <span className="hour">{startTime}</span>
                        <div className="img-wrp">
                          <img src={item.Files.length > 1 ? item.Files[1].Url: item.Files[0].Url} alt="Lorem" />
                          <h2>
                            {item.Title} {this.isActiveProgram(item.StartTime, item.EndTime) && <span className="live">YAYINDA</span>}
                          </h2>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={() => {
                    this.onClickNextDay();
                    window.scrollTo(0, 0);
                  }}
                  className="next-day-button"
                >
                  <i className="fa fa-chevron-right"></i> SONRAKİ GÜN
                </button>
              </div>
            );
          }
        }}
      </Query>
      </>
    );
  }
}

export default BroadCast;
