// const MARGIN = 10;
let shownToasts = [];
let toastHeight = window.innerHeight / 12;

export const toastType = {
  default: 'default',
  success: 'success',
  danger: 'danger',
  error: 'error',
};

/*****************************
 * Pozisyon geliştirilmesi henüz yapılmadı.
 * Peşpeşe gönderilen toastlar top pozisyonlarda alta doğru
 * bottom pozisyonlarda üste doğru konumu hesaplanarak basılmalı.
 * showToast fonksiyonu içerisinde yapılmalı.
 * Şuan sadece bottomCenter pozisyonu implemente edilmiştir.
 ****************************/

export const toastPosition = {
  bottomCenter: 'bottomCenter',
  bottomLeft: 'bottomLeft',
  bottomRight: 'bottomRight',
  topCenter: 'topCenter',
  topLeft: 'topLeft',
  topRight: 'topRight',
};

export function showToast(content, time, type /*position*/) {
  const toast = document.createElement('div');
  toast.innerHTML = content;
  toast.classList.add('toast');
  if (type !== undefined) toast.classList.add(type);
  else toast.classList.add(toastType.default);
  //   if(position !== undefined) toast.classList.add(position);
  //   else toast.classList.add(toastPosition.bottomCenter);
  toast.style.bottom = toastHeight + 'px';
  document.getElementsByTagName('body')[0].appendChild(toast);
  shownToasts.push(toast);
  // const { height } = toast.getBoundingClientRect();
  // toastHeight += MARGIN + height;
  setTimeout(() => hideToast(toast), time);
}

function hideToast(toast) {
  shownToasts = shownToasts.filter(e => e !== toast);
  // const { height } = toast.getBoundingClientRect();
  // toastHeight -= MARGIN + height;
  document.getElementsByTagName('body')[0].removeChild(toast);
  adjustPositioning();
}

function adjustPositioning() {
  toastHeight = window.innerHeight / 12;
  for (let i = 0; i < shownToasts.length; i++) {
    const toast = shownToasts[i];
    toast.style.bottom = toastHeight + 'px';
    // const { height } = toast.getBoundingClientRect();
    // toastHeight += MARGIN + height;
  }
}
