import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { CONTACT_VARIABLES, CONTACT_QUERY } from '../../query/contact';
import Loading from '../../components/loading/loading';
import HtmlParse from 'html-react-parser';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="static-page-container">
        <Query variables={CONTACT_VARIABLES} query={CONTACT_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />;
            } else if (error) console.error(error);
            else {
              return (
                <div className="contact-container">
                  <Helmet>
                    <title>{data.SuperContent.Title} - Radyo D</title>
                  </Helmet>
                  <h2>{data.SuperContent.Title}</h2>
                  <div className="google-maps">
                    <iframe
                      title="GoogleMaps"
                      src={this.props.config.GoogleMapsUrl}
                      width={window.innerWidth}
                      height="450"
                      frameborder="0"
                      allowfullscreen
                    />
                  </div>
                  {HtmlParse(data.SuperContent.Text)}
                </div>
              );
            }
          }}
        </Query>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({...state.radioReducer})
const contactPage = withRouter(props => <ContactPage {...props} />)
const ContactPageWithRouter = connect(mapStateToProps)(contactPage);

export default ContactPageWithRouter;
