import gql from 'graphql-tag';

export const GET_TOP20_QUERY = gql`
  query getSuperContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Id
      Url
      IxName
      Title
      Description
      CreatedBy
      StartDate
      Similars {
        MediaFiles {
          Path
        }
        Files {
          Id
          Url(Q:75,W:500,H:500) 
        }
        Description
        ContentTags {
          Name
        }
      }
    }
  }
`;

export const GET_TOP20_VARIABLES = {
  url: '/playlist/top-20-listesi',
};

export const GET_CURRENT_TOP20_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Id
    }
  }
`;

export const GET_CURRENT_TOP20_VARIABLES = {
  top: '1',
  skip: '0',
  filter: "ContentType eq 'PlayList' and Ancestors/any(a: a/SelfPath eq '/playlist/')",
  q: "['PlayList']",
  orderBy: 'StartDate desc',
};

export const GET_TOP20_DETAIL_QUERY = gql`
  query getContentById($Id: String!) {
    Content(Id: $Id) {
      Id
      Url
      IxName
      Title
      Description
      CreatedBy
      StartDate
      Similars {
        MediaFiles {
          Path
        }
        Files {
          Id
          Url(Q:75,W:500,H:500) 
        }
        Description
        ContentTags {
          Name
        }
      }
    }
  }
`;
