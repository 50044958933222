
let swiperParams = {
  slidesPerView: 'auto',
  spaceBetween: 20,
};

swiperParams = {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 20,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-navigation-next',
    prevEl: '.swiper-navigation-prev',
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
};

export default swiperParams;
