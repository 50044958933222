import * as React from "react"
const TwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={23}
    fill="none"
    viewBox="0 0 32 28"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.941 14.896.233 0h9.274l7.228 9.207L24.456.042h5.108l-10.36 12.31L31.488 28h-9.246l-7.827-9.956-8.355 9.928H.925l11.017-13.076ZM23.59 25.24 5.92 2.76h2.238l17.648 22.48H23.59Z"
      clipRule="evenodd"
    />
  </svg>
)
export default TwitterIcon