import gql from 'graphql-tag';

export const GENERAL_CONTENT_QUERY = gql`
  query getSuperContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Id
      Url
      IxName
      Title
      Text
    }
  }
`;

export const GENERAL_CONTENT_VARIABLES = {
  url: '/radyod-genel-aydinlatma-metni',
};
