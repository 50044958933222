import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

class InputValidator extends ValidatorComponent {
  render() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

    return (
      <div className="validate-input-container">
        <input
          {...rest}
          ref={r => {
            this.input = r;
          }}
        />

        <label htmlFor={rest.id}>{rest.placeholder}</label>
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;
    if (isValid) {
      return <span className="input-valid-message" />;
    }
    return <span className="input-valid-message">{this.getErrorMessage()}</span>;
  }
}

export default InputValidator;
