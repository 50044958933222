import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastType, showToast, toastPosition } from '../../common/toast';
import TwitterIcon from '../icons/TwitterIcon'

class SocialShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url == null ? this.props.config.ProductionUrl : this.props.url,
      title: this.props.title == null ? '' : this.props.title,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', event => {
      if (event.isComposing || event.keyCode === 27 /* Esc keycode = 27*/) {
        this.closePopup();
      }
    });
  }


  copyToClipboard = e => {
    navigator.clipboard.writeText(this.props.url).then(function () {
      showToast('Kopyalandı!', 2000, toastType.success, toastPosition.bottomCenter);
    }, function (err) {
        showToast('Kopyalanamadı!', 2000, toastType.error, toastPosition.bottomCenter);
    });
  };

  closePopup = () => {
    this.props.visibleEvent !== undefined && this.props.visibleEvent(false);
  };

  render() {
    return (
      <>
        <span onClick={() => this.setState({ visible: true })}>{this.props.children}</span>
        <div className={this.props.visible ? 'share-popup open' : 'share-popup'}>
          <div onClick={this.closePopup} className="share-popup-close-btn"></div>
          <div className="share-popup-container">
            <div className="title">PAYLAŞ</div>
            <div onClick={this.closePopup} className="share-popup-close-btn"></div>
            <ul className="row share-popup-btns">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.config.FacebookShareUrl +'?u=' + this.state.url + '&title=' + this.state.title}
                  className="facebook"
                >
                  <i className="fab fa-facebook"></i>
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.config.TwitterShareUrl + '?url=' + this.state.url + '&text=' + this.state.title + '&via=radyod104'}
                  className="twitter"
                >
                  <TwitterIcon/>
                  <span>Twitter</span>
                </a>
              </li>
            </ul>
            <div className="copylink">
              <div>
                <input type="text" className="share-url" ref={input => (this.copLink = input)} disabled value={this.props.url} />
              </div>
              <button type="button" onClick={this.copyToClipboard}>
                <i className="fas fa-copy"></i> Linki Kopyala
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({...state.radioReducer})
const socialShare = withRouter(props => <SocialShare {...props} />)
const SocialShareWithRouter = connect(mapStateToProps)(socialShare);

export default SocialShareWithRouter;
