import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Query } from 'react-apollo'
import { HEADER_FOOTER_QUERY, HEADER_FOOTER_VARIABLES } from '../../query/footer'

class ResponsiveBanner extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }    

    render() {
        const { config } = this.props
        return (
            <>
                <Query variables={HEADER_FOOTER_VARIABLES} query={HEADER_FOOTER_QUERY}>
                    {({ data, loading }) => {
                        if (loading || !data) {
                            return (
                                <Link to={'#'} key={'loading'}>
                                    YÜKLENİYOR...
                                </Link>
                            )
                        }

                        var responsiveBannerControl
                            = data.Menu.Template.Regions.find(a => a.IxName === 'Menu')
                                ?.Controls.find(a => a.IxName === 'ResponsiveBanner');

                        if (!responsiveBannerControl) {
                            return (null);
                        }

                        var isView = responsiveBannerControl.Properties.find(a => a.IxName === 'IsView')?.SelectValues?.find(x => x.Selected)?.IxName === 'true';
                        if (!isView) {
                            return (null);
                        }

                        var pageTitle = responsiveBannerControl.Properties.find(a => a.IxName === 'PageTitle')?.Value;
                        var link = responsiveBannerControl.Properties.find(a => a.IxName === 'Link')?.Value || '#';
                        var imgMobile = responsiveBannerControl.Properties.find(a => a.IxName === 'Mobile')?.Value;
                        var imgTablet = responsiveBannerControl.Properties.find(a => a.IxName === 'Tablet')?.Value;
                        var imgDesktop = responsiveBannerControl.Properties.find(a => a.IxName === 'Desktop')?.Value;

                        return (
                            <>
                                <div className="responsive-banner">
                                    <div className="item">
                                        <a href={link}>
                                            <picture>
                                                <source media="(max-width: 575.98px)" height="250" srcSet={`${config.ImageServiceUrl}/0x0/${imgMobile}.jpg`} />
                                                <source media="(max-width: 991.98px)" height="90" srcSet={`${config.ImageServiceUrl}/0x0/${imgTablet}.jpg`} />
                                                <img src={`${config.ImageServiceUrl}/0x0/${imgDesktop}.jpg`} height="90" alt={pageTitle} />
                                            </picture>
                                        </a>
                                    </div>
                                </div>
                            </>
                        )
                    }}
                </Query>
            </>
        )
    }

}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const responsiveBanner = withRouter(props => <ResponsiveBanner {...props} />)
const ResponsiveBannerWithRouter = connect(mapStateToProps)(responsiveBanner);

export default ResponsiveBannerWithRouter;
