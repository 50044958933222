import gql from 'graphql-tag';

export const NEWS_QUERY = gql`
  query filter($filter: String!, $q: String!, $skip: Int!, $top: Int!, $orderBy: String!) {
    Filter(Filter: $filter, Q: $q, Skip: $skip, Top: $top, OrderBy: $orderBy) {
      Ancestors {
        Title
      }
      Id
      Url
      IxName
      Title
      Duration
      Description
      Text
      ModifiedDate
      StartDate
      Files {
        Url
      }
    }
  }
`;

export const NEWS_VARIABLES = {
  top: '25',
  skip: '0',
  filter: "Ancestors/any(a: a/SelfPath eq '/haberler/' or a/SelfPath eq '/foto-galeriler/')",
  q: "['Article']",
  orderBy: 'StartDate desc',
};

export const NEWSCOUNT_QUERY = gql`
  query filtercount($filter: String!, $q: String!, $orderBy: String!) {
    FilterCount(Filter: $filter, Q: $q, OrderBy: $orderBy) {
      Count
    }
  }
`;

export const NEWSCOUNT_VARIABLES = {
  filter: "Ancestors/any(a: a/Id eq '57fcb9d5f5ac764c604f1edb' or a/Id eq '59bbb8e1ac1fe32fe46a81ad') and (ContentType ne 'Clip')",
  q: '',
  orderBy: 'StartDate desc',
};
