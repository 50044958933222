import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { FREQUENCY_QUERY, FREQUENCY_VARIABLES } from '../../query/frequency';
import Loading from '../../components/loading/loading';
import VectorMap from './vectormap';
import { isWeb } from '../../common/helper';
import { Helmet } from 'react-helmet';

class Frequency extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Helmet>
        <title>Radyo D Frekansları (Tüm Şehirler İçin) - Radyo D</title>
        <meta name='description' content="Radyo D Frekans sayfasında Türkiye'de tüm şehirler için Radyo D frekansını bulabilirsiniz."></meta>
        </Helmet>
        <div className="frequency-container">
        <Query variables={FREQUENCY_VARIABLES} query={FREQUENCY_QUERY}>
          {({ data, loading, error }) => {
            if (loading || !data) {
              return <Loading />;
            } else if (error) console.error(error);
            else {
              return (
                <div className="row">
                  {data.Filter.map((item, index) => {
                    return (
                      <>
                        <div key={index} style={{height: 'unset'}} className={index < 3 ? 'col-sm-12 col-md-4' : 'col-6 five-grid'}>
                          <div
                            className={index < 3 ? 'frequency-card bg-image large' : 'frequency-card bg-image'}
                            style={{
                              backgroundImage: 'url(' + item.Files[0].Url + ')',
                            }}
                          >
                            <label>{item.Title}</label>
                            <span>{item.Description}</span>
                          </div>
                        </div>
                        {
                          (index === 2 && isWeb()) &&
                          <VectorMap data={data.Filter.map(d => {
                            return {
                              name: d.IxName,
                              value: d.Description
                            }
                          })} />
                        }
                      </>
                    );
                  })}
                </div>
              );
            }
          }}
        </Query>
        </div>
      </>
    );
  }
}

export default Frequency;
