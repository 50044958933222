import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isWeb } from '../../common/helper';
import Pagination from '../../common/pagination'
import NewsCard from '../../components/card/newscard';
import VideoCard from '../../components/card/videocard';

class SearchPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isWeb: isWeb(),
            skip: 0,
            take: 15,
            isError: false,
            data: [],
            count: 0,
            currentPage: 1,
        }
    }

    getSearchValue = (skip, take) => {
        fetch(this.props.config.SearchUrl + '?q=' + encodeURIComponent(this.props.match.params.id) + '&skip=' + skip + '&take=' + take)
            .then(p => p.json())
            .then(data => {
                if (!data) {
                    return Promise.reject(
                        new Error(`Yükleme hatası: veri okunamadı`)
                    );
                }
                if (data.List) {
                    this.setState({ data: data.List, count: data.Count })
                } else {
                    // hatalı data formatı
                    this.setState({ isError: true })
                    return Promise.reject(
                        new Error(`Veri formatı hatalı`)
                    );
                }
            })
            .catch(error => {
                // data yok
                this.setState({ isError: true })
                console.log(`Error: ${error.message}`)
            });

    }

    componentDidMount() {
        this.getSearchValue(this.state.skip, this.state.take)
        window.addEventListener('resize', this.setState({isWeb: isWeb()}))
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.setState({ isWeb: isWeb() }))
    }

    onchangePage = (selectedPage) => {
        this.setState({ currentPage: selectedPage, skip: (selectedPage - 1) * this.state.take }, () => {
            this.getSearchValue((selectedPage - 1) * this.state.take, this.state.take)
        })
    }

    componentDidUpdate(prevProps){
        if (this.props.match.params.id !== prevProps.match.params.id)
            this.getSearchValue()
    }

    render() {
        return (
            <div className="search-page">
                <h2>"{this.props.match.params.id}" ARAMA SONUÇLARI</h2>
                {
                    this.state.data.length === 0
                        ? <div className="message">Sonuç Bulunamadı</div>
                        : <div className="row">
                            {
                                this.state.data.map((d,i) => {
                                    if(d.ContentType === 'Clip')
                                        return (
                                            <div className="col-md-4 search-content">
                                                <VideoCard
                                                    mobile={!this.state.isWeb}
                                                    key={i}
                                                    index={i}
                                                    url={d.Url}
                                                    title={d.Title}
                                                    imagePath={d.Images[0]}
                                                    date={d.StartDate}
                                                    duration={d.Duration}
                                                />
                                            </div>
                                        )
                                        
                                    return (
                                        <div className="col-md-4 search-content">
                                            <NewsCard
                                                mobile={!this.state.isWeb}
                                                key={i}
                                                index={i}
                                                url={d.Url}
                                                title={d.Title}
                                                imagePath={d.Images[0]}
                                                date={d.StartDate}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                }

                <Pagination
                    onchangePage={this.onchangePage}
                    current={this.state.currentPage}
                    itemCount={this.state.count}
                    pageSize={this.state.take}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ ...state.radioReducer })
const searchPage = withRouter(props => <SearchPage {...props} />)
const SearchPageWithRouter = connect(mapStateToProps)(searchPage);

export default SearchPageWithRouter;
