import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";

import App from "./App";

import registerServiceWorker from "./registerServiceWorker";

import rootReducer from "./reducers";
import { fetchConfig } from "./common/helper";
import { setConfig } from "./redux/radio/action";
import Page404 from "./common/404";

export const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
fetchConfig().then(config => {
    if (config != null) {
        setConfig(config)

        const globalAbortController = new AbortController();

        const client = new ApolloClient({
            link: ApolloLink.from([
                new HttpLink({ uri: config.MobilApiUrl }),
                onError(({ graphQLErrors, networkError }) => {
                    if (networkError) {
                        console.log(`[Network error]: ${networkError}`);
                    }
                    if (graphQLErrors) {
                        graphQLErrors.forEach(({ message, locations, path }) =>
                            console.log(
                                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                            )
                        );
                    }
                })
            ]),
            cache: new InMemoryCache(),
            defaultOptions: {
                query: {
                    context: {
                        fetchOptions: {
                            signal: globalAbortController.signal,
                        },
                    },
                },
                mutation: {
                    context: {
                        fetchOptions: {
                            signal: globalAbortController.signal,
                        },
                    },
                },
            }
        });        

        ReactDOM.render(
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            </ApolloProvider>,
            document.getElementById("root")
        );

        registerServiceWorker();
    } else {
        console.error("CONFIG", "Config bilgileri alınamadı: " + window.location.href + 'config.json')
        ReactDOM.render(<Page404 />, document.getElementById("root"))
    }

});
