import React, { useRef } from 'react'
import { Query } from 'react-apollo';
import { useSelector } from 'react-redux';
import { LIGHTBOX_QUERY, LIGHTBOX_VARIABLES } from '../../query/lightbox';
import ReactPlayer from 'react-player';
import { SocialMediaAccounts } from '../social-media-accounts';

export default function Lightbox () {
  const [play, setPlay] = React.useState(false)

  const config = useSelector(x => x.radioReducer.config)

  const modalRef = useRef(null)
  
  React.useEffect(() => {
    window.onclick = function(event) {
      if (event.target === modalRef.current) {
        handleClick('hide')
      }
    }
  })
  /**
   * 
   * @param {'hide' |'show'} className 
   */
  function handleClick (className) {
    const removed = className === 'show' ? 'hide' : 'show'
    modalRef.current.classList.remove(removed)
    modalRef.current.classList.add(className)
    if (className === 'hide') {
      setPlay(false)
    }
  }

  /**
   * 
   * @param {*} SuperContent 
   * @returns {any[]}
   */
  function getProperties(SuperContent){
    try {
      return SuperContent.Template.Regions[0].Controls[0].Properties || []
    } catch (error) {
      return []
    }
  }
/**
   * 
   * @param {*} SuperContent 
   * @returns {boolean}
   */
  function isMediaFiles (SuperContent){
    try {
      return (SuperContent && SuperContent.MediaFiles ? SuperContent.MediaFiles : []).length > 0
    } catch (error) {
      return false
    }
  }
/**
 * 
 * @param {*} SuperContent 
 * @returns {string}
 */
  function getRedirectInfo(SuperContent){
    const item = getProperties(SuperContent).find(x => x.IxName === 'RedirectUrl')
    return item ? item.Value : ''
  }

  function canRender (SuperContent) {
    const mediFiles = SuperContent && SuperContent.MediaFiles ? SuperContent.MediaFiles : []
    const files = SuperContent && SuperContent.Files ? SuperContent.Files : []
    if (files.length > 0 || mediFiles > 0) {
      const promo = getProperties(SuperContent).find(x => x.IxName === 'IsActivePromo')
      if (promo) {
        const values = Array.isArray(promo.SelectValues) ? promo.SelectValues : []
        const value = values.find(x => x.IxName === 'true')
        return value && value.Selected
      } 
    }
    return false
  }

  function handleRedirect(SuperContent){
    const link = getRedirectInfo(SuperContent)
    if (!link) return

    window.open(`//${link}`)
  }

  const render = ({ SuperContent }) => {
    if (!canRender(SuperContent)) {
      return
    }
    if(isMediaFiles(SuperContent)){
      const url = config.MediaUrl + SuperContent.MediaFiles[0].Path
      return (
        <div className="player-wrapper">
          <ReactPlayer
            onPlay={() => setPlay(true)}
            onPause={() => setPlay(false)}
            onEnded={() => setPlay(false)}
            className="player"
            playing={play}
            config={{ file: { forceHLS: true } }}
            controls
            url={url}
          />
        </div>
      )
    }
    const file = Array.isArray(SuperContent.Files) &&  SuperContent.Files.length > 0 ? SuperContent.Files[0] : null
    if (file) {
      return (
        <img onClick={() => handleRedirect(SuperContent)} src={file.Url} alt="" className="img"/>
      )
    }
  }

  const renderTitle = ({ SuperContent }) => {
    const file = SuperContent.Files[0]
    const title = file.Metadata.Title
    return <span onClick={() => handleRedirect(SuperContent)}>{title}</span>
  }
  
  return (
    <Query variables={LIGHTBOX_VARIABLES} query={LIGHTBOX_QUERY}>
    {({ data, loading, error }) => {
      if (loading || !data) return null
      if (error) console.error(error);
      else {
        return canRender(data.SuperContent) ? (
          <div className="modal-container">
          <div ref={modalRef} className="lightbox-modal show">
            <div className="modal-content">
              <span onClick={() => handleClick('hide')} className="lightbox-modal-close-btn">&times;</span>
              <div className="modal-inner">
                {render(data)}
              </div>
              <div className="modal-footer">
                { renderTitle(data)}
                <SocialMediaAccounts />
              </div>
            </div>
          </div>
        </div>
        ) : null
      }
    }}
    </Query>
    
  )
}