import { reduxConstants } from '../constants'
import LoadingProgramImage from '../../img/loading-program-image.png'

const LoadingImage = require('../../img/loading-card-image.png?v=2');

const initialState = {
    config: {},
    radioData: {
        programName: 'Yükleniyor...',
        programImage: LoadingProgramImage,
        artistName: '',
        songName: 'Yükleniyor...',
        songImage: LoadingImage,
        nextProgramName: 'Yükleniyor...',
        nextProgramImage: LoadingImage,
    },
    playingState: {
        page: null,
        item: null,
        index: null
    }
}

export function radioReducer(state = initialState, action) {
    switch (action.type) {
        case reduxConstants.SET_DATA:
            return Object.assign({}, state, { ...action });
        case reduxConstants.GET_DATA:
            return state;
        default:
            return state
    }
}
