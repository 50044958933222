import gql from 'graphql-tag';

export const APP_FORM_QUERY = gql`
  query getSuperContentByUrl($url: String!) {
    SuperContent(Url: $url) {
      Id
      Url
      IxName
      Title
      Text
    }
  }
`;

export const APP_FORM_VARIABLES = {
  url: '/radyod-ilgili-kisi-basvuru-formu',
};
