import React from "react";
import { Query } from 'react-apollo';
import { GET_PROGRAMLIST_QUERY, GET_PROGRAMLIST_VARIABLES } from '../../query/programlist';
import Loading from '../../components/loading/loading';
import { Helmet } from 'react-helmet';

class ResumePage extends React.Component{ 
    returnToProgram = () => {
      this.props.history.push("/program");
    }
    render(){
        return (
            <Query variables={GET_PROGRAMLIST_VARIABLES} query={GET_PROGRAMLIST_QUERY}>
              {({ data, loading, error }) => {
                if (loading || !data) {
                  return <Loading />;
                } else if (error) console.error(error);
                else
                  data = data?.Filter.find(item => item.IxName === this.props.match.params.id);
                  console.log(data)
                    return (
                      <div key={data?.Id} className="col-12 resume">
                        <Helmet>
                          <title>{data.Title} - Radyo D</title>
                          <meta name='description' content={data.Description}></meta>
                        </Helmet>
                        <div className="resume-return" onClick={this.returnToProgram}><i className="fa fa-chevron-left"></i> PROGRAMLAR</div>
                        <div className="resume-container">
                          <figure>
                            <img src={data.Files[0].Url} alt={data.Title} />
                          </figure>
                          <div className="resume-container-info">
                            <h3>{data.Title}</h3>
                            <div className="resume-container-info-title">
                              Hakkında
                            </div>
                            <div className="resume-container-info-text">
                              <p dangerouslySetInnerHTML={{__html:data?.Text}}></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
              }}
            </Query>
        )
    }
}

export default ResumePage;